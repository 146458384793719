import React, { FC, useRef, useState, useEffect, ChangeEvent } from 'react'
import styled from 'styled-components'

import { useFauxnamiCode } from '@ally/fauxnami'
import {
  Box,
  TextHeading,
  TextBody,
  FormText,
  get,
  Space,
} from '@ally/metronome-ui'

import SlidingDrawer from '@ally/metronome-sliding-drawer'
import { useBootstrap } from '../../providers'
import { tracks, TrackingEvent } from '../../tracking'

const FeatureFlagBox = styled(Box)`
  & + & {
    border-top: 1px solid black;
  }
`

const StyledSlidingDrawer = styled(SlidingDrawer)`
  width: 100%;
  ${get.media.up('md')} {
    width: 500px;
  }
`

const Code = styled.pre`
  display: inline-block;
  padding: ${get.space('xxs')};
  background-color: ${get.colors('slate-1')};
  border: 1px solid ${get.colors('slate-4')};
  border-radius: 2px;
  font-size: ${get.fontSizes('xs')};
  white-space: pre-wrap;
`

/**
 * Displays user's feature flags.
 * Use the `featureFlagInfo` keycode to enable the drawer (see constants.ts).
 */
export const FeatureFlagInfo: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const code = useFauxnamiCode('featureFlagInfo')
  const { services } = useBootstrap()

  const allFlags = services?.featureFlags.bank.client.allFlags() || {}
  const featureFlags = Object.entries(allFlags).sort(([keyA], [keyB]) =>
    keyA.localeCompare(keyB),
  )

  const [query, setQuery] = useState('')
  const [inputDisabled, setInputDisabled] = useState(true)

  useEffect(() => {
    if (!inputDisabled) inputRef.current?.focus()
  }, [inputDisabled])

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setQuery(e.target.value)
  }

  const filteredFlags = featureFlags.filter(([key]) =>
    key.toLowerCase().includes(query.toLowerCase()),
  )

  return (
    <StyledSlidingDrawer
      show={code.active}
      onOpening={tracks(TrackingEvent.FeatureFlagPanelOpened)}
      onOpened={(): void => setInputDisabled(false)}
      onClosing={(): void => {
        setInputDisabled(true)
        code.disable()
      }}
      isAriaModal
    >
      <Space mb="8px">
        <TextHeading tag="h2" size="sm">
          Feature Flag Info
        </TextHeading>
      </Space>
      <FormText
        inputType="text"
        value={query}
        onChange={onChange}
        placeholder="Search Feature Flags"
        disabled={inputDisabled}
        ref={inputRef}
        variant="input"
        labelContent=""
      />
      {filteredFlags.map(([key, value]) => (
        <FeatureFlagBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py="xs"
          key={key}
          flexWrap="wrap"
        >
          <Box flexShrink={0} mr="sm">
            <TextBody tag="span" size="sm">
              {key}
            </TextBody>
          </Box>
          <Code>{JSON.stringify(value, null, 2)}</Code>
        </FeatureFlagBox>
      ))}
    </StyledSlidingDrawer>
  )
}

export default FeatureFlagInfo
