import { useBootstrap } from '../../providers/bootstrap'
import { FeatureFlag } from './flags'

// When passing true/false below as a fallback, the return type is `true` or
// `false` instead of `boolean` (as expected); this fixes that common use case.
type R<T> = T extends boolean ? boolean : T

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useGetFeatureFlag() {
  const { services } = useBootstrap()

  return <T>(flagName: FeatureFlag | string, fallback: T): R<T> => {
    return services
      ? services.featureFlags.bank.client.variation(flagName, fallback)
      : fallback
  }
}

/**
 * Convenience method to get the value of a feature flag.
 * Until bootstrapping is complete, this will return the fallback value.
 *
 * @example
 * const flagValue = useFeatureFlag(FeatureFlag.SomeFlag, false)
 */
function useFeatureFlag<T>(flagName: FeatureFlag | string, fallback: T): R<T> {
  const getFeatureFlag = useGetFeatureFlag()

  return getFeatureFlag(flagName, fallback)
}

export { FeatureFlag, useGetFeatureFlag, useFeatureFlag }
