/**
 * Returns whether or not the given key exists on the given object.
 * **DOES NOT** check the object's prototype.
 */
export function hasKey<T extends Record<any, any>>(
  obj: T,
  k: keyof any,
): k is keyof T {
  return k in obj
}
