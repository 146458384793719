import { useEffect } from 'react'
import { delay } from '@ally/utilitarian'
import { emberPrefetchDelay, emberPrefetchEnabled } from '../../constants'

import PrefetchWorker from '../../workers/prefetch.worker'

/**
 * A react hook that will "prefetch" all of the assets in `emberPrefetchAssets`.
 * This will load the Ember asset map from '/assets/assetMap.json`, the start
 * prefetching assets (up to 3 at a time).
 *
 * It will wait `emberPrefetchDelay` ms prior to prefetching.
 * This is used to allow for time for the page to load "essential assets" before
 * beginning the prefetching process.
 */
export function useEmberPrefetch(): void {
  useEffect(() => {
    if (typeof window.Worker === 'function' && emberPrefetchEnabled) {
      delay(emberPrefetchDelay).then(() => {
        return new PrefetchWorker()
      })
    }
  }, [])
}
