import React from 'react'
import { MaxAppWidth } from '@ally/next-layout'

import log from '../../whisper'
import { UnknownError } from '../../routes/ErrorPage'

interface ErrorBoundaryState {
  error: Error | null
}

/**
 * An error boundary that renders a generic error page if any errors are thrown.
 * Since this application only renders global components and remote
 * applications—if an error is encountered—we're showing the "temporarily
 * unavailable page". Remotes should handle their own errors accordingly.
 */
export default class ErrorBoundary extends React.Component<
  Record<string, unknown>,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(e: Error): ErrorBoundaryState {
    log.error({ message: ['ErrorBoundary Exception:', e.stack] })
    return { error: e }
  }

  constructor(props: Record<string, unknown>) {
    super(props)
    this.state = { error: null }
  }

  render(): React.ReactNode {
    const { error } = this.state
    const { children } = this.props

    if (!error) return children

    return (
      <MaxAppWidth>
        <UnknownError />
      </MaxAppWidth>
    )
  }
}
