import React, {
  createContext,
  useMemo,
  useState,
  useContext,
  useCallback,
} from 'react'

import { useHistory } from 'react-router'

import {
  Session,
  BaseSession,
  SessionStatus,
  SessionStatuses,
  SessionData,
} from '@ally/federated-types'

import { noop } from '@ally/utilitarian'
import {
  TransmitRef,
  LegacySession,
  TransmitAuthPayload,
} from '@ally/transmitigator'

import { useSessionStorage } from './use-session-storage'
import { useAccessTokenRefresh } from './useAccessTokenRefresh'
import { useTransmitRef } from '../transmit-ref'
import { track, TrackingEvent, withTrackingEventsAsync } from '../../tracking'

const {
  Rehydrating,
  Authenticated,
  Authenticating,
  Unauthenticated,
} = SessionStatuses

function getLoginRedirect(pathname: string | false): string {
  return pathname && !['/', '/login'].includes(pathname)
    ? `?redirect=${pathname}`
    : ''
}

function hasStatus(x: SessionStatus) {
  return ({ status }: Session): boolean => x === status
}

export const isRehydrating = hasStatus(Rehydrating)
export const isAuthenticated = hasStatus(Authenticated)
export const isAuthenticating = hasStatus(Authenticating)
export const isUnauthenticated = hasStatus(Unauthenticated)

export function createSession({
  data,
  status = data ? Authenticated : Unauthenticated,
  destroy = noop,
}: Partial<BaseSession> = {}): BaseSession {
  return {
    data: data ?? null,
    status,
    destroy,
  }
}

export const anonymousSession = Object.freeze(createSession())
export type SessionContextValue = Session & {
  fetchLegacySession: (tokenType?: string) => any
}
export const SessionContext = createContext<SessionContextValue>(
  {} as SessionContextValue,
)
export const useSession = (): SessionContextValue => useContext(SessionContext)

interface SessionLogoutOptions {
  reason?: string
  redirectURL?: string
}

const isValidUrl = (redirectURL: string): boolean => {
  let url
  try {
    url = new URL(redirectURL, window.location.origin)
  } catch (err) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

/**
 * Handles when a user logs out.
 * If given a page to redirect to on logout, the user will be redirected.
 * Otherwise the default redirect url is used.
 */

export function handleLogout(options?: SessionLogoutOptions): void {
  const { reason, redirectURL } = options ?? {}
  const sanitizedUrl =
    redirectURL && isValidUrl(redirectURL)
      ? new URL(redirectURL, window.location.origin)
      : new URL('/ext-storefront/logged-off/', window.location.origin)

  if (reason) {
    sanitizedUrl.searchParams.append('reason', reason)
  }
  window.location.assign(sanitizedUrl)
}

type SessionDataPayload = TransmitAuthPayload | null

type LegacyResponsePayload = LegacySession | { legacyChatToken: string }

const handleLegacyData = (
  legacySessionData: SessionDataPayload,
): LegacyResponsePayload => {
  switch (legacySessionData?.type) {
    case 'legacySession':
      return legacySessionData.legacySession
    case 'chatTokenRefresh':
      return { legacyChatToken: legacySessionData.legacyChatToken }
    default:
      return {} as LegacyResponsePayload
  }
}

interface LegacySessionProps {
  transmitRef: TransmitRef
  session: BaseSession
  tokenType?: string
  setSession: (s: BaseSession) => void
}

export async function createLegacySession({
  transmitRef,
  session,
  tokenType,
  setSession,
}: LegacySessionProps): Promise<LegacyResponsePayload | null> {
  const transmitHook = transmitRef.useTransmit()

  if (!session.data) return null

  const config = {
    access_token: session.data.access_token,
    username: session.data.userNamePvtEncrypt,
    journey_version: 'v2',
    token_type: tokenType,
  }
  const getLegacySession = withTrackingEventsAsync(
    transmitHook.actions.legacySession,
    [TrackingEvent.LegacySessionInit, TrackingEvent.LegacySessionDone],
  )
  const legacySessionData = await getLegacySession(config)
  const legacyData = handleLegacyData(legacySessionData)

  // set chat token expiry to current time plus 14 mins
  const chatTokenExpiry = new Date().getTime() + 14 * 60 * 1000

  const sessionData = { ...session.data, ...legacyData, chatTokenExpiry }
  const legacySession: Partial<BaseSession> = {
    data: sessionData,
  }

  setSession({ ...session, ...legacySession })
  return legacyData
}

/**
 * The Session Provider.
 * Provides information about the current user session and method for
 * manipulating its state. Includes within its context:
 *
 * data        - The session "data" (like a decoded JWT data example)
 * status      - The status of the session.
 * delSession - Resets to an anonymous session (with status === Unauthenticated)
 * setSession - Sets the session state (data and optional status)
 * requireAuth - Requires that the user is authenticated, or redirects to the
 *               login page, with a ?redirect=xxx param set. This will redirect
 *               the user back to the referring page once authenticated.
 */
export const SessionProvider: React.FC = ({ children }) => {
  const [hasAttemptedLegacy, setHasAttemptedLegacy] = useState(false)
  const history = useHistory()
  const sessionStorage = useSessionStorage()
  const { transmitRef } = useTransmitRef()

  const [session, updateSession] = useState<BaseSession>(anonymousSession)
  const { data, status, destroy } = session

  const requireAuth = useCallback(
    (redirect: string | false = window.location.pathname) => {
      if (status !== Authenticated) {
        history.replace(`/${getLoginRedirect(redirect)}`)
      }
    },
    [status, history],
  )

  const logout = useCallback(
    async (options?: SessionLogoutOptions) => {
      const { reason, redirectURL } = options ?? {}
      try {
        await destroy({ journey_version: 'v2' })
      } catch (e) {
        // Track error and continue logout process
        const message = e instanceof Error ? e.message : 'Unknown Error.'
        track(TrackingEvent.LogoutError, { message })
      }
      sessionStorage.onLogout()
      handleLogout({
        redirectURL,
        reason,
      })
    },
    [destroy, sessionStorage],
  )

  /**
   * Legacy function - is just an alias for `logout` - will be deprecated in a future release
   */
  const delSession = useCallback((reason?: string) => logout({ reason }), [
    logout,
  ])

  const setSession = useCallback((nextSession: Partial<BaseSession> | null) => {
    updateSession(createSession(nextSession ?? undefined))
  }, [])

  // ----- Access Token Refresh Logic -----
  const {
    refreshing: accessTokenRefreshing,
    refreshAccessToken,
  } = useAccessTokenRefresh(session, setSession)

  const refresh = (): Promise<SessionData | null> => {
    return refreshAccessToken({
      refreshData: true,
    })
  }

  const fetchLegacySession = useCallback(
    async (tokenType?: string) => {
      return createLegacySession({
        transmitRef,
        session,
        setSession,
        tokenType,
      })
    },
    [transmitRef, session, setSession],
  )

  const transmitHook = transmitRef.useTransmit()

  const addAllyId = useCallback(async (): Promise<string> => {
    const sessionData = session.data
    // Create config object for addAllyId action
    if (!sessionData)
      throw new Error(
        'User is not authenticated. Unable to call transmit addAllyId journey',
      )
    const config = {
      access_token: sessionData.access_token,
      username: sessionData.userNamePvtEncrypt,
    }
    track(TrackingEvent.AddAllyIdInit)
    // Get new session object with allyId
    const response = await transmitHook.actions.addAllyId(config)
    /**
     * Send info to log rocket
     */
    track(
      response?.type === 'session'
        ? TrackingEvent.AddAllyIdDone
        : TrackingEvent.AddAllyIdError,
    )
    if (!response || response.type !== 'session') {
      throw new Error('Invalid response from transmit addAllyId journey')
    }
    const newsessionData = { ...session.data, ...response.session }
    const legacySession: Partial<BaseSession> = {
      data: newsessionData,
    }

    setSession({ ...session, ...legacySession })
    return response.session.allyid
  }, [session, setSession, transmitHook])

  useMemo(() => {
    if (session.status === 'Authenticated' && !hasAttemptedLegacy) {
      fetchLegacySession()
      setHasAttemptedLegacy(true)
    }
  }, [session.status, hasAttemptedLegacy])

  // Provider value
  const value = useMemo(
    () => ({
      data,
      status,
      delSession,
      setSession,
      requireAuth,
      accessTokenRefreshing,
      fetchLegacySession,
      addAllyId,
      refresh,
      logout,
    }),
    [
      data,
      status,
      delSession,
      setSession,
      requireAuth,
      accessTokenRefreshing,
      fetchLegacySession,
      addAllyId,
      refresh,
      logout,
    ],
  )

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  )
}
