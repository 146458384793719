import { Session } from '@ally/federated-types'
import { useEffect, useRef } from 'react'
import { BehaviorSubject } from 'rxjs'
import { useSession } from './SessionProvider'

export const useSession$ = (): BehaviorSubject<Session | null> => {
  const { current: session$ } = useRef(
    new BehaviorSubject<Session | null>(null),
  )
  const session = useSession()

  useEffect(() => {
    session$.next(session)
  }, [session])

  return session$
}
