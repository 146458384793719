// -------------------------------------------------------------------------- //
// WARNING: THIS CONTENT IS DUPLICATED IN `src/layout/print/PrintFooter.tsx`
// IF YOU CHANGE IT HERE, YOU PROBABLY SHOULD CHANGE IT THERE...
// -------------------------------------------------------------------------- //

import * as React from 'react'
import { Button, Modal, Space, useModalContext } from '@ally/metronome-ui'
import { ConfirmNavigationModal } from '../Modals'
import { DisclosuresText } from './shared'

type TriggerProps = {
  content: string
}

const Trigger: React.FC<TriggerProps> = ({ content }) => {
  const { setOpen } = useModalContext()
  return (
    <Button
      variant="link"
      text={content}
      data-track-name={content}
      onClick={(): void => setOpen(true)}
    />
  )
}

const AutoDisclosure: React.FC = () => {
  return (
    <Space mb="sm">
      <DisclosuresText tag="p" size="sm">
        Ally Auto accounts serviced by Ally Servicing LLC,{' '}
        <Modal
          trigger={<Trigger content="NMLS ID 212403" />}
          content={
            <ConfirmNavigationModal url="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/212403" />
          }
          height="auto"
          data-track-name="NMLS ID 212403 modal"
          data-track-elem="modal"
        />
        .
      </DisclosuresText>
    </Space>
  )
}

export { AutoDisclosure }
