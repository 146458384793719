import React, { useEffect } from 'react'
import { useGlobalLocation } from '@ally/federator'

type EffectProps = {
  pathname: string
}

const ScrollToTopEffect: React.FC<EffectProps> = ({ pathname }) => {
  useEffect(() => {
    // Don't scroll to top when already at the top,
    // or when targeting an id (e.g. /stuff#really-cool-stuff)
    const isScrolling = window.scrollY !== 0 && !pathname.includes('#')

    if (isScrolling) {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [pathname])

  return <></>
}

const ScrollToTop = (): React.ReactElement => {
  const { pathname } = useGlobalLocation()
  return <ScrollToTopEffect pathname={pathname} />
}

export { ScrollToTop, ScrollToTopEffect }
