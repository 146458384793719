import React from 'react'

import Location from './Location'
import Applications from './Applications'
import { DebugPanelSection } from '../Shared'

const ApplicationDetails: React.FC = () => {
  return (
    <DebugPanelSection title="Application Details">
      <Location />
      <Applications />
    </DebugPanelSection>
  )
}

export default ApplicationDetails
