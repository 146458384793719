import { useMemo } from 'react'

import { LoadState } from '@ally/use-data-domain'
import { FeatureFlagClientName } from '@ally/federated-types'

import { useBootstrap } from '../../../providers'
import { useAutoLDRegister } from './use-auto-ld-register'
import { useBankLDRegister } from './use-bank-ld-register'
import { useRoutingLDRegister } from './use-routing-ld-register'

/**
 * Registers the user with LaunchDarkly once all the necessary information is
 * available. To register a user with launch darkly we need the user's guid
 * (or tpn if Auto-only user), username, account types, and the featureFlags
 * services to be available.
 */
export function useLDRegister(): Record<FeatureFlagClientName, LoadState> {
  const { services } = useBootstrap()

  const auto = useAutoLDRegister(services?.featureFlags.auto)
  const bank = useBankLDRegister(services?.featureFlags?.bank)
  const routing = useRoutingLDRegister(services?.featureFlags?.routing)

  const registrationStates = useMemo(() => ({ auto, bank, routing }), [
    auto,
    bank,
    routing,
  ])

  return registrationStates
}
