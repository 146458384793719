/* eslint-disable camelcase */

import React, { useMemo, useContext, createContext } from 'react'

import {
  HostData,
  AfgAccountsDomain,
  AfgCustomerDomain,
  BadWordsDomain,
  CustomizationDomain,
  RestrictedDatesDomain,
  NotificationsDomain,
} from '@ally/data-types-host'

import { useDataDomain, getDefaultDomainState } from '@ally/use-data-domain'

import {
  accounts,
  customer,
  customization as customizationUUIP,
  restrictedDates as restrictedDatesUUIP,
} from '@unified-ui/ally-next-host'

import { isAuthenticated, useSession } from '../session'

import { useFeatureFlag, FeatureFlag, useIsOnly } from '../../hooks'

import { fetchNotifications, fetchBadWords } from '../../utils/fetch'
import { UUIPConverter } from './UUIPConverter'
import { env } from '../../constants'

export const HostDataContext = createContext<HostData>({
  afgAccounts: getDefaultDomainState<AfgAccountsDomain>(null),
  afgCustomer: getDefaultDomainState<AfgCustomerDomain>(null),
  badWords: getDefaultDomainState<BadWordsDomain>(null),
  customization: getDefaultDomainState<CustomizationDomain>(null),
  restrictedDates: getDefaultDomainState<RestrictedDatesDomain>(null),
  notifications: getDefaultDomainState<NotificationsDomain>(null),
})

export const useHostData = (): HostData => useContext(HostDataContext)

// TODO: Maybe move this to @ally/which-env?
type EnvironmentName = 'dev' | 'qa1' | 'qa2' | 'qa3' | 'psp' | 'prod'
type UUIPEnvironment = `web-${EnvironmentName}`
const UUIP_ENVIRONMENT: UUIPEnvironment = env.pick({
  local: 'web-dev',
  dev: 'web-dev',
  qa1: 'web-qa1',
  qa2: 'web-qa1',
  qa3: 'web-qa1',
  cap: 'web-qa1',
  psp: 'web-psp',
  prod: 'web-prod',
})

/**
 * The HostData Provider.
 * Provides global, stateful API domain "data". That is, the set of data that
 * will be passed down to remote applications as `hostData`.
 *
 * Each data object is wrapped in the following format:
 *
 * const hostDataState = {
 *   ...,
 *   [<namespace>]: {
 *     data,
 *     error,
 *     update: () => void,
 *     response: Response,
 *     status: {
 *       loadState: LoadState,
 *       timeStamp: Date,
 *     },
 *   },
 * }
 *
 * ...where...
 *
 * - data      : is the raw data returned from the fetch function.
 * - error     : is any error thrown by fetch.
 * - response  : is the fetch Response with the body already consumed.
 * - timeStamp : is the last time the data state was updated.
 * - loadState : is one of: unloaded, failed, loading, or loaded.
 */
export const HostDataProvider: React.FC = ({ children }) => {
  const session = useSession()
  const hasAuth = isAuthenticated(session)

  const badWords = useDataDomain<BadWordsDomain>({
    fetch: () => fetchBadWords(),
    fireLoad: true,
  })

  const restrictedDates = useDataDomain<RestrictedDatesDomain>({
    fetch: () =>
      UUIPConverter(
        (restrictedDatesUUIP.api.get({
          environment: UUIP_ENVIRONMENT,
        }) as unknown) as Promise<RestrictedDatesDomain>,
      ),
    fireLoad: true,
  })

  const { guid } = session.data ?? {}
  const hasGuid = Boolean(guid) && guid !== 'null'

  // Customization
  const isCustomizationEnabled = useFeatureFlag(
    FeatureFlag.CustomizationEnabled,
    true,
  )

  const isAutoOnly = useIsOnly('auto')
  const customizationFireLoad =
    isCustomizationEnabled && !isAutoOnly && hasGuid && hasAuth

  const customization = useDataDomain<CustomizationDomain>({
    fetch: () =>
      UUIPConverter(
        customizationUUIP.api.get({
          access_token: session.data?.access_token || '',
          environment: UUIP_ENVIRONMENT,
          guid: session.data?.guid,
        }),
      ),
    fireLoad: customizationFireLoad,
  })

  const notifications = useDataDomain<NotificationsDomain>({
    fetch: () => fetchNotifications(session.data),
    fireLoad: hasAuth,
  })

  // Accounts
  const afgAccounts = useDataDomain<AfgAccountsDomain>({
    fetch: () =>
      UUIPConverter(
        accounts.api.get({
          ...session.data,
          access_token: session.data?.access_token || '',
          environment: UUIP_ENVIRONMENT,
        }),
      ),
    fireLoad: hasAuth,
  })

  const afgCustomer = useDataDomain<AfgCustomerDomain>({
    fetch: () =>
      UUIPConverter(
        customer.api.get({
          access_token: session.data?.access_token || '',
          environment: UUIP_ENVIRONMENT,
        }),
      ),
    fireLoad: hasAuth,
  })

  const value = useMemo(
    () => ({
      afgAccounts,
      afgCustomer,
      badWords,
      customization,
      restrictedDates,
      notifications,
    }),
    [
      afgAccounts,
      afgCustomer,
      badWords,
      customization,
      restrictedDates,
      notifications,
    ],
  )

  return (
    <HostDataContext.Provider value={value}>
      {children}
    </HostDataContext.Provider>
  )
}
