import styled from 'styled-components'
import { TextBody, get } from '@ally/metronome-ui'

export const DisclosuresText = styled(TextBody)`
  color: ${get.colors('slate-4')};

  a {
    white-space: normal;
  }
`
