import { deprecated } from '@ally/masquerade'
import LogRocket from 'logrocket'

export const logRocketSdkServer = 'https://cdn.lr-hv-in.com'
export const logRocketIngestServer = 'https://r.lr-hv-in.com'

type LR = typeof LogRocket
type lrOptions = Parameters<LR['init']>[1]

export const logRocketConfig: lrOptions = {
  release: APP_BUILD_INFO?.release ?? 'unknown',
  rootHostname: 'ally.com',
  console: {
    isEnabled: true,
    shouldAggregateConsoleErrors: true,
  },
  dom: {
    isEnabled: true,
    baseHref: 'https://secure.ally.com/',
  },
}

// LEGACY
const legacySanitizerOptions: deprecated.Config = {
  ...deprecated.defaultConfigs,
  // We should never transfer sensitive data to/from this endpoint from the UI
  // however, the fuzzy search will at time mask useful debugging information.
  ignoredDomains: ['https://app.launchdarkly.com'],
}

export const LegacyMasquerade = (): deprecated.Masquerade =>
  deprecated.default(legacySanitizerOptions)
