const isObject = (v: any): boolean =>
  Object.prototype.toString.call(v) === '[object Object]'

export type ProxyObject<T extends Record<string | symbol | number, any>> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any
    ? T[K]
    : T[K] extends Record<string | symbol | number, any>
    ? ProxyObject<T[K]>
    : T[K]
}

export function toProxyObject<T extends Record<string | symbol | number, any>>(
  obj: T,
): ProxyObject<T> {
  const handleGet = <U extends Record<string | symbol | number, any>>(
    target: U,
    prop: keyof U,
  ): any => {
    if (isObject(target[prop])) {
      return toProxyObject(target[prop])
    }
    if (typeof target[prop] === 'function') {
      return target[prop].bind(target)
    }
    if (Array.isArray(target[prop])) {
      return target[prop].map((_: never, i: keyof U, a: U) => handleGet(a, i))
    }
    return Reflect.get(target, prop)
  }

  return new Proxy(obj, {
    get(target, prop): any {
      return handleGet(target, prop)
    },
    getOwnPropertyDescriptor(target, prop): PropertyDescriptor | undefined {
      const protoKeys = Object.getOwnPropertyNames(
        Object.getPrototypeOf(target),
      )
      if (
        typeof prop === 'string' &&
        protoKeys.includes(prop) &&
        typeof target[prop] === 'function'
      ) {
        return {
          value: target[prop].bind(target),
          writable: true,
          enumerable: true,
          configurable: true,
        }
      }
      return Reflect.getOwnPropertyDescriptor(target, prop)
    },
    ownKeys(target): ArrayLike<string | symbol> {
      const protoKeys = Object.getOwnPropertyNames(
        Object.getPrototypeOf(target),
      )
      return Reflect.ownKeys(target).concat(...protoKeys)
    },
  })
}
