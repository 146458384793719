import { FC, RefObject } from 'react'
import styled from 'styled-components'

export interface RemoteAppContainerProps {
  isLoading: boolean
  ref?: RefObject<HTMLDivElement>
}

export const RemoteAppContainer: FC<RemoteAppContainerProps> = styled.div(
  ({ isLoading }: RemoteAppContainerProps) => `
    display: ${isLoading ? 'none' : 'block'};
  `,
)
