import { AutoProfile, Session } from '@ally/federated-types'
import { constant, memoizePromise, noop, once } from '@ally/utilitarian'
import { BehaviorSubject, filter, pairwise } from 'rxjs'
import { track, TrackingEvent } from '../../tracking'
import Timer from '../timer'
import { toProxyObject } from '../toProxyObject'
import { KEEP_ALIVE_INTERVAL } from './constants'
import { AutoLoginServiceArgs } from './types'
import {
  autoKeepAliveWithTransmit,
  autoLoginWithTransmit,
  autoLogoutWithTransmit,
  hasAutoProfileId,
  hasAutoRelationship,
} from './utils'

export class AutoLoginService {
  private login = memoizePromise(autoLoginWithTransmit, constant(0))

  private session$: BehaviorSubject<Session | null>

  private keepAliveTimer: Timer = new Timer({
    time: KEEP_ALIVE_INTERVAL,
    repeat: true,
  })

  private profile: AutoProfile | null = null

  constructor(args: AutoLoginServiceArgs) {
    this.session$ = args.session$
    // Setup Automatic Login
    this.session$
      .pipe(
        pairwise(),
        // Filter to only when session changes to authenticated
        filter(
          ([prev, next]) =>
            prev?.status !== next?.status && next?.status === 'Authenticated',
        ),
        // Filter to only valid Auto users
        filter(
          ([_, session]) =>
            hasAutoRelationship(session) && hasAutoProfileId(session),
        ),
      )
      // Do nothing on failure - Logging is in function.
      .subscribe(() => this.getProfile().catch(noop))
    // Setup Keep Alive
    this.keepAliveTimer.events$
      .pipe(filter(e => e.name === 'TIMEOUT'))
      .subscribe(this.keepAlive.bind(this))
  }

  /**
   * Calls the AAOS `/login` endpoint for the currently logged in user and
   * returns the profile data from the API reponse. This function is memoized
   * so that subsequent calls will just return the cached profile.
   *
   * This function will throw errors for the following situations:
   * - `NOT_TRANSMIT_AUTHENTICATED` - User not authenticated or has no auth data
   * - `NO_AUTO_RELATIONSHIP` - The current user does not have an Auto relationship
   * - `NO_PROFILE_ID` - The current user does not have a AAOS profile id
   * - `LOGIN_API_ERROR`
   *   - Network response's `ok` property was false OR
   *   - Network response does not contain profile data
   *
   * NOTE: You may recieve other errors not listed above when the fetch fails
   */
  async getProfile(): Promise<AutoProfile> {
    track(TrackingEvent.AAOSLoginInit)
    try {
      const profile = await this.login(this.session$.getValue())
      this.profile = profile
      this.keepAliveTimer.start()
      track(TrackingEvent.AAOSLoginDone)
      return profile
    } catch (e) {
      this.reset()
      const message = e instanceof Error ? e.message : 'Unknown Error.'
      track(TrackingEvent.AAOSLoginError, { message })
      throw e
    }
  }

  private async keepAlive(): Promise<void> {
    track(TrackingEvent.AAOSKeepAliveInit)
    try {
      await autoKeepAliveWithTransmit(this.session$.getValue(), this.profile)
      track(TrackingEvent.AAOSKeepAliveDone)
    } catch (e) {
      this.reset()
      const message = e instanceof Error ? e.message : 'Unknown Error.'
      track(TrackingEvent.AAOSKeepAliveError, { message })
    }
  }

  /**
   * Calls the AAOS `/logout` endpoint for the currently logged in user.
   *
   * This function will throw errors for the following situations:
   * - `NOT_TRANSMIT_AUTHENTICATED` - User not authenticated or has no auth data
   * - `NO_AUTO_PROFILE` - There is no AAOS profile in state for current user
   * - `NO_CSRF_TOKEN` - The AAOS profile in state does not have a CSRF token
   * - `LOGOUT_API_ERROR` - Network response's `ok` property was false
   *
   * NOTE: You may recieve other errors not listed above when the fetch fails
   */
  async logout(): Promise<void> {
    track(TrackingEvent.AAOSLogoutInit)
    try {
      await autoLogoutWithTransmit(this.session$.getValue(), this.profile)
      this.reset()
      track(TrackingEvent.AAOSLogoutDone)
    } catch (e) {
      this.reset()
      const message = e instanceof Error ? e.message : 'Unknown Error.'
      track(TrackingEvent.AAOSLogoutError, { message })
      throw e
    }
  }

  private reset(): void {
    this.login.cache.clear()
    this.profile = null
    this.keepAliveTimer.stop()
  }
}

export default once((args: AutoLoginServiceArgs) =>
  toProxyObject(new AutoLoginService(args)),
)
