import * as React from 'react'
import styled from 'styled-components'
import { FlexDirectionProps } from 'styled-system'
import { deprecated, MuiIconMap } from '@ally/metronome-icons'
import {
  Box,
  Button,
  Icon,
  Link as MuiLink,
  Modal,
  Space,
  TextBody,
  TextHeading,
  upToMd,
  useModalContext,
} from '@ally/metronome-ui'

const Trigger: React.FC = () => {
  const { setOpen } = useModalContext()
  return (
    <Button
      variant="link"
      text="Feedback"
      onClick={(): void => setOpen(true)}
    />
  )
}

type ContentBoxProps = {
  icon: MuiIconMap
  title: string
  content: string
  withLeftBorder?: boolean
  linkAsButton?: boolean
  onLinkClick?: () => void
  href?: string
  newTab?: boolean
  id?: string
  closeModalOnClick?: boolean
}

const StyledContentBox = styled(Box)<{ withLeftBorder: boolean }>`
  ${({ withLeftBorder, theme: { colors, media, space } }): string =>
    withLeftBorder
      ? `
    border-left-style: none;
    padding-left: ${space.xs};
    margin-left: ${space.xs};

    ${media.up('md')} {
      border-left-style: solid;
      border-left-width: 1px;
      border-left-color: ${colors['slate-1']};
    }
  `
      : ''}
`

const CenteredTextBody = styled(TextBody)`
  text-align: center;
`

const ContentBox: React.FC<ContentBoxProps> = ({
  icon,
  title,
  content,
  withLeftBorder,
  linkAsButton,
  onLinkClick,
  href = '',
  newTab = false,
  id,
  closeModalOnClick = false,
}) => {
  const { setOpen } = useModalContext()

  const handleClick = (): void => {
    if (closeModalOnClick) setOpen(false)
    onLinkClick?.()
  }

  return (
    <StyledContentBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      withLeftBorder={!!withLeftBorder}
    >
      <Icon
        icon={icon}
        fill="bluesuedeshoes"
        margin="0 auto"
        size="xl"
        ariaHidden
      />
      <Box width="fit-content">
        {linkAsButton && (
          <Button variant="link" id={id} text={title} onClick={handleClick} />
        )}
        {!linkAsButton && newTab && (
          <MuiLink text={title}>
            <a
              href={href}
              onClick={handleClick}
              target="_blank"
              rel="noreferrer"
            >
              content replaced by metronome
            </a>
          </MuiLink>
        )}
        {!linkAsButton && !newTab && (
          <MuiLink text={title}>
            <a href={href} onClick={handleClick}>
              content replaced by metronome
            </a>
          </MuiLink>
        )}
      </Box>
      <CenteredTextBody tag="p" size="sm">
        {content}
      </CenteredTextBody>
    </StyledContentBox>
  )
}

type ContentProps = FeedbackModalProps
const Content: React.FC<ContentProps> = ({ onFeedbackClick }) => (
  <Box display="flex" flexDirection="column" m="none">
    <Space mt="sm" mb="md">
      <TextHeading tag="h2" size="md" focusOnMount>
        Tell us what you think
      </TextHeading>
    </Space>
    <Box
      display="flex"
      mb="md"
      flexDirection={
        upToMd('column', 'row') as FlexDirectionProps['flexDirection']
      }
    >
      <ContentBox
        icon={deprecated.muiChatFeedback}
        id="give-feedback-link"
        title="Give us some feedback"
        content="We use your feedback to improve your online banking experience"
        onLinkClick={onFeedbackClick}
        linkAsButton
        closeModalOnClick
      />
      <ContentBox
        icon={deprecated.muiStarOutline}
        title="Review our products"
        content="Tell the world how much you love your Ally Bank account"
        href="/ext-storefront/bank/customer-reviews"
        newTab
        withLeftBorder
      />
    </Box>
  </Box>
)

type FeedbackModalProps = {
  onFeedbackClick: () => void
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ onFeedbackClick }) => {
  return (
    <Modal
      trigger={<Trigger />}
      content={<Content onFeedbackClick={onFeedbackClick} />}
    />
  )
}

export { FeedbackModal }
