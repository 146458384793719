import initializeLaunchDarkly, { AllyLaunchDarkly } from '@ally/aardvarkly'
import { FeatureFlagClientName } from '@ally/federated-types'
import { memoize } from '@ally/utilitarian'

import {
  TrackingEvent,
  TrackingEvents,
  withTrackingEventsAsync,
} from '../../../tracking'

import {
  launchDarklyAutoEnvKey,
  launchDarklyBankEnvKey,
  launchDarklyRoutingEnvKey,
} from '../../../constants'

import getLogRocket from '../../../services/logRocket/service'

export type FeatureFlagClients = Record<FeatureFlagClientName, AllyLaunchDarkly>

export const getLDClientBankMemo = memoize(() =>
  initializeLaunchDarkly({
    key: launchDarklyBankEnvKey,
    platform: 'react',
  }),
)

export const getLDClientRoutingMemo = memoize(() =>
  initializeLaunchDarkly({
    key: launchDarklyRoutingEnvKey,
    platform: 'react',
    ldConfig: { sendEventsOnlyForVariation: false, evaluationReasons: true },
  }),
)

export const getLDClientAutoMemo = memoize(() =>
  initializeLaunchDarkly({
    key: launchDarklyAutoEnvKey,
    platform: 'react',
  }),
)

const events: Record<FeatureFlagClientName, TrackingEvents> = {
  auto: [TrackingEvent.LDAutoInit, TrackingEvent.LDAutoDone],
  bank: [TrackingEvent.LDBankInit, TrackingEvent.LDBankDone],
  routing: [TrackingEvent.LDRoutingInit, TrackingEvent.LDRoutingDone],
}

/**
 * Loads the feature flag (LaunchDarkly) instances for:
 *
 *   1. Routing: Used to generate the federated route schema.
 *   2. Bank: Used by the "Bank" experiences.
 *
 * Note, if a client fails to initialize, the @ally/aardvarkly package will log
 * an error and initialization will fail gracefully.
 */
export default async (): Promise<FeatureFlagClients> => {
  const auto = getLDClientAutoMemo()
  const bank = getLDClientBankMemo()
  const routing = getLDClientRoutingMemo()

  await Promise.all([
    withTrackingEventsAsync(auto.getInitPromise, events.auto)(),
    withTrackingEventsAsync(bank.getInitPromise, events.bank)(),
    withTrackingEventsAsync(routing.getInitPromise, events.routing)(),
  ])

  const useAllowList = bank.client.variation('FF_ally-next-allowlist', false)
  if (useAllowList) {
    const masqueradeConfig = bank.client.variation('FF_allowlist-config', {})
    getLogRocket().setMasqueradeConfig(masqueradeConfig)
  }

  return {
    auto,
    bank,
    routing,
  }
}
