import { useEffect, useState } from 'react'
import { BaseSession, SessionData } from '@ally/federated-types'
import { useTimer } from '../../hooks'
import { useTransmitRef } from '../transmit-ref'
import { track, TrackingEvent } from '../../tracking'

export interface UseAccessTokenRefreshHook {
  refreshing: boolean
  refreshAccessToken: (options?: {
    refreshData?: boolean
  }) => Promise<SessionData | null>
}

const ACCESS_TOKEN_REFRESH_INTERVAL = (5 * 60 - 10) * 1000 // 10 sec before 5 minutes

export function useAccessTokenRefresh(
  session: BaseSession,
  setSession: (s: BaseSession) => void,
): UseAccessTokenRefreshHook {
  const [refreshing, setRefreshing] = useState(false)
  const { transmitRef } = useTransmitRef()
  const transmitHook = transmitRef.useTransmit()

  // Refresh access token and update session
  const refreshAccessToken = async (
    options = {},
  ): Promise<SessionData | null> => {
    if (!session.data) return null

    setRefreshing(true)
    const {
      // eslint-disable-next-line camelcase
      access_token,
      userNamePvtEncrypt: username,
    } = session.data
    const authData = await transmitHook.actions.refreshAccessToken({
      ...options,
      access_token,
      username,
      journey_version: 'v2',
    })
    track(
      authData !== null
        ? TrackingEvent.RefreshAccessTokenDone
        : TrackingEvent.RefreshAccessTokenError,
    )

    const nextSession: Partial<BaseSession> = {
      data:
        authData?.type === 'session'
          ? { ...session.data, ...authData.session }
          : null,
      status: authData ? 'Authenticated' : 'Unauthenticated',
    }

    // Previously we have had issues where data domain state was not being reset
    // when the user becomes unauthenticated, causing the previous data to stay
    // in application memory and not re cleaned.
    // This should be addressed more cleanly in the future where the entirety of
    // Host state is cleaned based on the user's authentication status
    if (nextSession.status !== 'Authenticated') {
      // Trigger full refresh of the app and land on login
      window.location.assign('/')
    } else {
      setSession({ ...session, ...nextSession })
    }
    setRefreshing(false)

    return {
      ...session.data,
      ...nextSession.data,
    }
  }

  // Setup timer to call refresh function
  const { clearTimer, resetTimer } = useTimer(
    refreshAccessToken,
    ACCESS_TOKEN_REFRESH_INTERVAL,
  )

  // Reset timer every time the session data changes
  useEffect(() => {
    clearTimer()
    if (session.data) resetTimer()
  }, [session])

  return { refreshing, refreshAccessToken }
}
