import React, { Suspense } from 'react'
import { MaxAppWidth } from '@ally/next-layout'

import Switch from './Switch'
import { SkeletonLoader } from '../components'

const Router: React.FC = () => {
  return (
    <Suspense fallback={<SkeletonLoader />}>
      <MaxAppWidth>
        <Switch />
      </MaxAppWidth>
    </Suspense>
  )
}

export default Router
