import { useEffect, useState } from 'react'
import { LoadState } from '@ally/use-data-domain'
import log from '../../whisper'
import { useSession } from '../../providers'
import { dynatraceEnabled } from '../../constants'

/**
 * Filters property object to be sent to Dynatrace.
 * Limits included properties to those with a valid value, and
 * filters out properties that have a value of undefined or null and omits
 * them from the properties object.
 */
function filterNilPropeties(obj: Record<string, any>): Record<string, any> {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => ![undefined, null].includes(value),
    ),
  )
}

/**
 * Registers the user with Dynatrace once all the necessary information is
 * available. To register a user with Dynatrace we need the user's guid, or
 * tpn if the user is an auto-only customer. `dynatraceEnabled` must also be
 * set to true.
 */
export function useDTRegister(): LoadState {
  const [loadState, setRegistrationLoadState] = useState(LoadState.LOADING)

  const { data } = useSession()
  const dtUserId = data?.userNamePvtEncrypt
  const service = 'ally-online-services'

  useEffect(() => {
    if (
      loadState === LoadState.LOADED ||
      !dtUserId ||
      !dynatraceEnabled ||
      !window.dtrum
    )
      return

    window.dtrum?.identifyUser(dtUserId)

    setRegistrationLoadState(LoadState.LOADED)
    log.info({ message: '[USER] Registered with Dynatrace' })

    window.dtrum?.sendSessionProperties(
      undefined,
      undefined,
      filterNilPropeties({
        aaos_id: data?.aaosId,
        cif: data?.cif,
        cupid: data?.cupid,
        guid: data?.guid,
        invest_id: data?.investId,
        service,
        tpn: data?.tpn,
        username: data?.userNamePvtEncrypt,
      }),
    )
  }, [dtUserId, loadState])

  return loadState
}
