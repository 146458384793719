import * as React from 'react'
import { Box, get } from '@ally/metronome-ui'
import { MaxAppWidth } from '@ally/next-layout'
import styled from 'styled-components'
import { Links } from './LinksSection'
import { DisclosureSection } from './Disclosures'

const AppFooter = styled.footer`
  padding: ${get.space('xxl')} ${get.space('none')};
  margin: auto;
  flex: 0;
  color: ${get.colors('slate-4')};
  width: 100%;

  @media print {
    display: none;
  }
`

const AppFooterContent = styled.div`
  padding-top: ${get.space('md')};
  border-top: 1px solid #ddd;
  font-size: ${get.bodySizes('sm')};
`

const Footer: React.FC = () => (
  <AppFooter data-track-region="footer" className="aos-application-container">
    <MaxAppWidth bg="page-grey">
      <AppFooterContent>
        <Links />
        <DisclosureSection />
      </AppFooterContent>
      <Box display="none" id="feedback" />
    </MaxAppWidth>
  </AppFooter>
)

export default Footer
