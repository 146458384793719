import { env } from '../../../constants'

type Host = 'dev' | 'qa' | 'prod'

interface DocumentBody extends HTMLBodyElement {
  width?: number
  height?: number
}

Object.defineProperty(window, 'digitalData', {
  value: {},
  writable: true,
})

export const createDDO = (
  host: string,
  width: number,
  height: number,
): Record<string, unknown> => {
  const html = document.getElementsByTagName('html').item(0)

  return {
    cart: {},
    component: [],
    event: [],
    delayPageLoadEvent: true,
    loadRules: {
      adobeAnalytics: true,
      adobeAudienceManager: true,
      adobeTarget: true,
      celebrus: true,
      confirmit: true,
      googleAnalytics: true,
      livePerson: true,
    },
    page: {
      pageInfo: [
        {
          language: html ? html.lang.substring(0, 2) : 'Unknown',
          destinationURL: document.location.href,
          pageID: `${host}-_-AOS`,
          pageName: '',
          referringURL: document.referrer,
          sysEnv: window.orientation ? 'mobile' : 'desktop',
        },
      ],
      category: {
        lineOfBusiness: 'Bank',
        primaryCategory: 'AOS:',
        site: 'AOS',
        section: '',
        subsection: '',
      },
      // Extensible object of PAGE-LEVEL information:
      //   React - Indicates whether this is a React page (includes original react app)
      //   isSPA - Indicates whether this is a single page application
      //   allyNext - Indicates whether this is an ally next page
      attributes: {
        React: true,
        isSPA: true,
        allyNext: true,
        // Extensible object containing browser details:
        //   breakpoint  - Small | Medium | Large | X-Large
        //   dimensions  - clientHeight from document.body
        //   orientation - Browser orientation
        browser: {
          breakpoint: '',
          dimensions: `${width}x${height}`,
          orientation: '',
        },
        country: navigator.language
          ? navigator.language.substring(3, 5)
          : 'Unknown',
        formValues: {},
        // Extensible object for search related parameters:
        //   gclid    - From gclid query string parameter
        //   intcmpid - Internal campaign code
        search: {
          gclid: '',
          intcmpid: '',
        },
      },
    },
    pageInstanceId: '',
    product: [],
    privacy: {},
    transaction: {},
    user: {},
    version: 'Ally_1.4',
  }
}

const devLaunchScript = 'launch-f9375026550a-development.min.js'
const stagingLaunchScript = 'launch-4755404c6f6c-development.min.js'
const prodLaunchScript = 'launch-7205b4f8d243.min.js'

const loadAdobeLaunchLib = (): void => {
  /** Determine the correct script to load */
  const lib = env.pick({
    local: devLaunchScript,
    dev: devLaunchScript,
    cap: stagingLaunchScript,
    fix: stagingLaunchScript,
    qa1: stagingLaunchScript,
    qa2: stagingLaunchScript,
    qa3: stagingLaunchScript,
    prod: prodLaunchScript,
  })

  const libUrl = `//assets.adobedtm.com/cd392cd56789/6e78a0a7390a/${lib}`
  const isLoaded = !!document.head.querySelector(`script[src="${libUrl}"]`)

  if (!isLoaded) {
    const libScript = document.createElement('script')
    libScript.setAttribute('src', libUrl)
    libScript.setAttribute('async', '')
    document.head.appendChild(libScript)
  }
}

export const determineHostForPageID = (): Host => {
  const host = env.name
  if (host.includes('qa')) return 'qa'
  return host !== 'prod' ? 'dev' : host
}

export const mountDigitalDataObject = (): void => {
  const host = determineHostForPageID()
  const body = document.body as DocumentBody

  Object.assign(
    window.digitalData,
    createDDO(
      host,
      body.width || body.clientWidth,
      body.height || body.clientHeight,
    ),
  )

  // Load Adobe script after everything is in place
  loadAdobeLaunchLib()
}
