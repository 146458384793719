import React, { ReactNode } from 'react'
import { MuiIconMap } from '@ally/metronome-icons'
import { Link } from '@ally/metronome-ui'
import { bankNumbers } from '../../constants'

export interface ErrorPageProps {
  icon?: MuiIconMap
  title?: string | ReactNode
  error?: string | ReactNode | Error
  className?: string
}

export interface ErrorPageSchema {
  heading: ReactNode
  message?: ReactNode
}

export type ErrorComponent = React.FC<ErrorPageProps>
export type ErrorDefinition = ErrorPageSchema | ErrorComponent
export type ErrorPageComponents = Record<ErrorCode, React.FC<ErrorPageProps>>

const SupportTelLink = (
  <Link text={bankNumbers.insideUS} href={bankNumbers.telLink} />
)

/**
 * A mapping of error codes to ErrorSchema or a React component.
 * The host (or remotes) can history.push('/error/:code') and based on the code
 * a "standard" ErrorPage will be rendered with the following properties.
 */
export const schema = {
  404: {
    heading: "Sorry! We can't find that page...",
    message: (
      <span data-testid="404-error-message">
        You may have mistyped the URL or requested a page that doesn&apos;t
        exist anymore. You can always call us at {SupportTelLink}. We&apos;re
        here 24/7.
      </span>
    ),
  },
  500: {
    heading: 'Request cannot be processed.',
    message: (
      <span data-testid="500-error-message">
        We&apos;re unable to process your request at this time. Please call us
        24/7 at {SupportTelLink} for help.
      </span>
    ),
  },
}

export type ErrorCode = keyof typeof schema
