import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'
import { SkeletonLoader } from '../components'

import ErrorPage from './ErrorPage'
import { ErrorPageProps } from './ErrorPage/schema'

/**
 * Triggers a full page refresh and shows loader while that is occuring
 */
const ReloadPage = (): ReactElement => {
  window.location.reload()
  return <SkeletonLoader />
}

/**
 * This <Switch /> is invoked when all federated routes fallthrough. These are
 * routes that are "native" to the host app.
 *
 * NOTE: Passing window.location as a location prop to observe history events
 * from other remotes. Note this is kind of fickle and could break in future
 * react-router-dom versions—but according to the code—only `pathname` is
 * used.
 *
 * @see
 * https://github.com/ReactTraining/react-router/blob/b529499efcb906c814b9a3a68e2b4292a15b09c8/packages/react-router/modules/Switch.js#L19
 */
const RouteSwitch: React.FC = () => {
  // Grabs state from history module's wrapped state...
  // Otherwise, fallback to regular old pushState/replaceState state.
  const currentState = window.history.state?.state || window.history.state

  return (
    <Switch location={{ ...window.location, state: currentState }}>
      <Route
        exact
        path={['/error', '/error/:code']}
        render={({ location: { state }, match: { params } }): ReactElement => (
          <ErrorPage
            code={params.code}
            icon={(state as ErrorPageProps)?.icon}
            error={(state as ErrorPageProps)?.error}
            title={(state as ErrorPageProps)?.title}
          />
        )}
      />
      <Route path={['/ext-storefront/*']} component={ReloadPage} />
      <Route path="/">
        <ErrorPage code={404} />
      </Route>
    </Switch>
  )
}

export default RouteSwitch
