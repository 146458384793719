import React from 'react'
import { useGlobalLocation } from '@ally/federator'

import { contentFederator, headerFederator } from '../../federation'
import { useWarpWithTicket } from './useWarpWithTicket'
import { useWarpgate } from './useWarpgate'
import { isWarpgateEnabled } from './utils'

/**
 * Blocks until the LD client is ready, then (maybe) warps.
 *
 * Since warpgate needs to run before anything is rendered, we're initializing
 * and waiting for (only) the bank launch darkly instance to be ready before
 * rendering anything.
 *
 * Note: This is a separate process from bootstrapping. Bootstrapping will
 * render the global components and skeleton loader... this will render a blank
 * screen until the LD client is ready. While it's unfortunate that we have to
 * block like this, it's a better experience than the header/footer flashing
 * right before jumping to another page.
 */

const Warpgate: React.FC = ({ children }) => {
  const location = useGlobalLocation()
  const wg = useWarpgate()
  const warpWithTicket = useWarpWithTicket(wg)
  const isActive = isWarpgateEnabled()

  if (!wg) return null

  const shouldWarp = isActive && wg.check(location.href, false)
  if (shouldWarp) {
    contentFederator.unlisten()
    headerFederator.unlisten()
    warpWithTicket(location)
  }

  return shouldWarp ? null : <>{children}</>
}

export default Warpgate
