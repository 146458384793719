import React, { createContext, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { noop } from '@ally/utilitarian'
import { HostServices } from '@ally/federated-types'
import { track, TrackingEvent } from '../../tracking'

interface MortgageRedirectInterface {
  redirectToMortgage: HostServices['redirectToMortgage']
}

export const MortgageRedirectContext = createContext<MortgageRedirectInterface>(
  { redirectToMortgage: noop },
)

export const useMortgageRedirect = (): MortgageRedirectInterface =>
  useContext(MortgageRedirectContext)

/**
 * Mortgage Redirect Provider. Exposes functionality to allow
 * remotes to execute a function to sso to mortgage, warning
 * the user about navigating away from our site.
 * Example usage...
 * ```
 * import { useMortgageRedirect } from '../providers'
 * const { redirectToMortgage } = useMortgageRedirect()
 *
 * <button onClick={() => redirectToMortgage(loanNumber?: string)}>Redirect</button>
 * ```
 */

export const MortgageRedirectProvider: React.FC = ({ children }) => {
  const history = useHistory()

  const redirectToMortgage = (loanIdentifier?: string): void => {
    track(TrackingEvent.MortgageRedirect)
    if (loanIdentifier) {
      history.push(`/sso/home-loans?id=${loanIdentifier}`)
    } else {
      history.push('/sso/home-loans')
    }
  }

  return (
    <MortgageRedirectContext.Provider
      value={{
        redirectToMortgage,
      }}
    >
      {children}
    </MortgageRedirectContext.Provider>
  )
}
