import { useGlobalLocation } from '@ally/federator'
import { useEffect, useRef, useState } from 'react'
import { useAsyncFocus } from './useAsyncFocus'

export const useAppFocus = (): void => {
  const focus = useAsyncFocus()
  const isMounted = useRef(false)
  const { pathname, search } = useGlobalLocation()
  const [currentPathname, setCurrentPathname] = useState(pathname)
  const isSamePathname = pathname === currentPathname

  useEffect(() => {
    if (!isMounted.current) return
    const [, focusParam = null] = search.match(/focus=([^&]+)/) || []
    if (focusParam) {
      const elementFound = focus(`[data-anchor-id=${focusParam}]`)
      if (!elementFound) {
        document.querySelector<HTMLElement>('#top-nav-ally-icon')?.focus()
      }
      return
    }
    focus('#top-nav-ally-icon')
  }, [currentPathname])

  useEffect(() => {
    if (!isSamePathname) {
      setCurrentPathname(pathname)
    }
  }, [isSamePathname])

  useEffect(() => {
    focus('#top-nav-ally-icon')
    isMounted.current = true
  }, [])
}
