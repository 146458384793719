import styled from 'styled-components'
import React, { ReactElement } from 'react'

import { mapValues } from '@ally/utilitarian'
import { deprecated } from '@ally/metronome-icons'
import { Box, get, Icon, TextBody, TextHeading } from '@ally/metronome-ui'
import { useTrackingEvent } from '../../hooks'

import {
  schema,
  ErrorCode,
  ErrorPageProps,
  ErrorPageSchema,
  ErrorPageComponents,
} from './schema'

const HeadingWrapper = styled(Box)`
  align-items: center;
  display: flex;
`

const ErrorTextHeading = styled(TextHeading)`
  color: ${get.colors('error')};
`

/**
 * Converts an ErrorSchema (with heading and message) to an <ErrorPage />.
 * The returned React component will accept ErrorPageProps and renders a
 * "Standard Error Page".
 *
 * Also, users can push their own error message by doing:
 *
 * @example
 * // Using a string
 * history.push('/error', {
 *   title?: 'This will override the error heading',
 *   error?: 'This will override the error text',
 * })
 *
 * // Using an error object, the error message will be the error text.
 * history.push('/error', {
 *   title: 'Oops...',
 *   error: new Error('Something went wrong...'),
 * })
 */
function getErrorPageForSchema({ heading, message }: ErrorPageSchema) {
  return ({
    icon = deprecated.muiWarningFill,
    error,
    title,
    className,
  }: ErrorPageProps): ReactElement => (
    <div className={className}>
      <HeadingWrapper mt="xxl" mb="md">
        <Icon fill="error" icon={icon} size="xl" customTitle="error" />
        <Box ml="xs">
          <ErrorTextHeading size="sm">{title || heading}</ErrorTextHeading>
        </Box>
      </HeadingWrapper>
      <TextBody tag="p" size="sm">
        {(error instanceof Error ? error.message : error) || message}
      </TextBody>
    </div>
  )
}

const ErrorPages: ErrorPageComponents = mapValues(schema, x =>
  typeof x === 'object' ? getErrorPageForSchema(x) : x,
)

/**
 * Given an error code (defaulting to 500), will lookup the associated component
 * and render the correct ErrorPage. If code is `0` or a component for the given
 * code doesn't exist, it renders the "Temporarily Unavailable" page as a
 * fallback.
 */
export const ErrorPage: React.FC<ErrorPageProps & { code?: ErrorCode }> = ({
  code = 500,
  icon,
  error,
  title,
  className,
}) => {
  const Component = ErrorPages[code] || ErrorPages[500]
  useTrackingEvent(`ally-next-host-error-page-${code}`)

  return (
    <Component icon={icon} error={error} title={title} className={className} />
  )
}

export default ErrorPage
export const UnknownError = ErrorPages[500]
