import cookies from 'js-cookie'
import log from '../whisper'
import {
  dynatraceEnabled,
  dynatraceSessionReplayEnabled,
  DYNATRACE_APP_NAME,
} from '../constants'
/**
 * Checks Browser storage for the given key and returns the value if found.
 * If no value is found, `undefined` is returned.
 * This checks in the following order: Cookies -> LocalStorage -> SessionStorage
 * which follows Dynatrace's storage patterns.
 *
 * More information can be found here:
 *
 * https://docs.dynatrace.com/docs/manage/data-privacy-and-security/data-privacy/cookies#cookie-storage
 */
function getFromBrowserStorage(key: string): string | undefined {
  return (
    cookies.get(key) ||
    localStorage.getItem(key) ||
    sessionStorage.getItem(key) ||
    undefined
  )
}

/**
 * Generates a URL to the active session.
 *
 * Note: This will not be a direct link to the session but rather the session search page.
 * This is because the direct URL includes timestamps we cannot get.
 */
function generateSessionURL(): string | null {
  const timeframe = Date.now()
  const internalUserId = getFromBrowserStorage('rxVisitor') // https://docs.dynatrace.com/docs/manage/data-privacy-and-security/data-privacy/cookies#dynatrace-rum-cookies
  if (!internalUserId) return null

  const baseURL =
    'https://xww15327.apps.dynatrace.com/ui/apps/dynatrace.classic.session.segmentation/ui/user-sessions'

  return `${baseURL}?gtf=${timeframe}&gf=all&filter=app%3A${DYNATRACE_APP_NAME}&filter=internalUserId%3A${internalUserId}`
}

/**
 * Initializes Dynatrace Services, including:
 *   - Realtime User Monitoring
 *   - Session Replay
 */
export default function initDynatrace(): void {
  // if dynatraceEnabled is false, do not load Dynatrace script
  if (!dynatraceEnabled) {
    log.warn({ message: '[NOTICE] DYNATRACE IS DISABLED!' })
    return
  }

  if (!window.dtrum) {
    log.error({ message: '[ERROR] DYNATRACE NOT FOUND IN WINDOW' })
    return
  }

  window.dtrum?.enable()
  log.info({ message: '[NOTICE] DYNATRACE IS ENABLED!' })

  const sessionURL = generateSessionURL()
  if (sessionURL) window.Dynatrace = { sessionURL }

  if (!dynatraceSessionReplayEnabled) {
    window.dtrum?.disableSessionReplay()
    log.warn({ message: 'Dynatrace Session Replay DISABLED...' })
    return
  }

  // see https://www.dynatrace.com/support/doc/javascriptapi/interfaces/dtrum_types.DtrumApi.html#enableSessionReplay
  // enableSessionReplay(false) specifies that the API call will NOT override the settings in Dynatrace console
  window.dtrum?.enableSessionReplay(false)
  log.info({ message: 'Dynatrace Session Replay Enabled...' })
}
