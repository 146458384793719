import { ApigeeFetchError } from '@ally/fido'
import { DomainFetch } from '@ally/use-data-domain'

interface UUIPDataConverter<T> extends Record<string, any> {
  data?: T | null | undefined
  errors?: ApigeeFetchError[]
}

export const UUIPConverter = async <D, T extends UUIPDataConverter<D>>(
  input: Promise<T>,
): Promise<DomainFetch<T>> => {
  const payload = await input

  return {
    payload,
  }
}
