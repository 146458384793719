import React, { useEffect } from 'react'
import { getJSONSessionStorage } from '@ally/utilitarian'
import { useGlobalLocation } from '@ally/federator'
import { useAriaLive } from '@ally/use-aria-live'
import { sessionFirstDashboardVisitKey } from '../../constants'

const WelcomeToAlly: React.FC = () => {
  const { pathname } = useGlobalLocation()
  const { announcePolite } = useAriaLive()
  useEffect(() => {
    const isDashboard = pathname === '/dashboard'
    const isFirstDashboardVisit =
      getJSONSessionStorage<boolean>(sessionFirstDashboardVisitKey) === true

    if (isDashboard && isFirstDashboardVisit) {
      announcePolite('Welcome to Ally.')
    }
  }, [pathname])

  return null
}

export default WelcomeToAlly
