import { useState } from 'react'
import { WG } from '@ally/warpgate'
import { useSession, useTransmitRef } from '../../providers'
import { track, TrackingEvent } from '../../tracking'

function appendTicketId({ href }: Location, ticketID: string): string {
  const url = new URL(href)
  url.searchParams.append('ticketID', ticketID)
  return url.toString()
}

/**
 * Warps user with `ticketID` from `transmit` appended to URL as query parameter.
 */
export function useWarpWithTicket(
  wg: WG | null,
): (location: Location) => Promise<void> {
  const [isWarping, setIsWarping] = useState(false)
  const { data: sessionData, accessTokenRefreshing } = useSession()
  const { transmitRef } = useTransmitRef()
  const transmit = transmitRef.useTransmit()

  return async (location): Promise<void> => {
    if (wg === null || isWarping || accessTokenRefreshing) return

    setIsWarping(true)

    /**
     * If not authenticated, warp without
     * a ticket
     */
    if (sessionData === null) {
      wg.warp('ember', location.href)
      return
    }

    /**
     * ====================
     * From here we know the url is either invest external or ember, and
     * will route with a ticketID appended to URL
     * ====================
     */

    /**
     * Get ticket ID from transmit ticket journey
     */
    const result = await transmit.actions.transferTicket({
      destination: 'bank_ember',
      access_token: sessionData.access_token,
      username: sessionData.userNamePvtEncrypt,
      journey_version: 'v2',
    })

    /**
     * Send info to log rocket
     */
    track(
      result?.type === 'ticket'
        ? TrackingEvent.TransferTicketDone
        : TrackingEvent.TransferTicketError,
    )

    /**
     * Generates URL to warp to, depending on transmit type
     */
    const url =
      result?.type !== 'ticket'
        ? location.href
        : appendTicketId(location, result.ticketID)

    /**
     * Determines if warping should replace window state
     */
    const shouldReplace = result?.type === 'ticket'

    wg.warp('ember', url, shouldReplace)
  }
}
