import { LoadState } from '@ally/use-data-domain'
import { accounts as uuipAccounts } from '@unified-ui/ally-next-host'
import { AllyUserRole, LOB } from '@ally/federated-types'

type AccountType = uuipAccounts.types.Account['type']

export function isDomainReady(state: LoadState): boolean {
  return state === LoadState.LOADED || state === LoadState.FAILED
}

export function getLOBGroups(roles: Partial<AllyUserRole> = {}): LOB[] {
  return (Object.keys(roles) as LOB[]).filter(lob => roles[lob])
}

export function getAccountTypes(
  accounts: uuipAccounts.types.Account[],
): AccountType[] {
  return Array.from(new Set([...accounts?.map(account => account.type)]))
}
