import { AutoLoginService, Session } from '@ally/federated-types'
import { noop } from '@ally/utilitarian'

export function getLogout(
  session: Session,
  autoLogin: AutoLoginService,
): () => Promise<void> {
  const isAutoUser = !!session.data?.allyUserRole.auto
  const autoLogout = isAutoUser
    ? autoLogin.logout
    : Promise.resolve.bind(Promise)
  return (): Promise<void> => autoLogout().catch(noop).then(session.logout)
}
