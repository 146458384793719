/**
 * Array of routes to use as a default when evaluating the 'react-routes' feature flag
 * This should be updated to match the current 'Production' variation when it changes
 * https://app.launchdarkly.com/default/production/features/react-routes/variations
 */
export const DEFAULT_REACT_ROUTES = [
  {
    match: '/',
    target: 'next',
  },
  {
    match: '/annual-profile-verification',
    target: 'next',
  },
  {
    match: '/contact',
    target: 'next',
  },
  {
    match: '/dashboard',
    target: 'next',
  },
  {
    match: '/dashboard-error',
    target: 'next',
  },
  {
    match: '/error',
    target: 'next',
  },
  {
    match: '/error/([0-9]+)',
    target: 'next',
  },
  {
    match: '/forgot-password',
    target: 'next',
  },
  {
    match: '/reset-password',
    target: 'next',
  },
  {
    match: '/security/(.*)',
    target: 'next',
  },
  {
    match: '/temp-unavailable',
    target: 'next',
  },
  {
    match: '/no-accounts',
    target: 'next',
  },
  {
    match: '/payments/pay-bills',
    target: 'next',
  },
  {
    match: '/payments/manage-non-ally-accounts',
    target: 'next',
  },
  {
    match: '/payments/debit-card',
    target: 'next',
  },
  {
    match: '/payments/debit-card/(.*)',
    target: 'next',
  },
  {
    match: '/payments/receive-wire',
    target: 'next',
  },
  {
    match: '/payments/send-wire',
    target: 'next',
  },
  {
    match: '/bank/checks-debit-cards',
    target: 'next',
  },
  {
    match: '/bank/correspondence',
    target: 'next',
  },
  {
    match: '/bank/documents-and-forms',
    target: 'next',
  },
  {
    match: '/bank/statements-and-forms',
    target: 'next',
  },
  {
    match: '/bank/statements-and-forms/(.*)',
    target: 'next',
  },
  {
    match: '/bank/overdraft-transfer-service',
    target: 'next',
  },
  {
    match: '/bank/deposit-slips-and-envelopes',
    target: 'next',
  },
  {
    match: '/bank/stop-payment',
    target: 'next',
  },
  {
    match: '/enroll',
    target: 'next',
  },
  {
    match: '/enroll/(.*)',
    target: 'next',
  },
  {
    match: '/profile',
    target: 'next',
  },
  {
    match: '/profile/(.*)',
    target: 'next',
  },
  {
    match: '/account/(.*)/details',
    target: 'next',
  },
  {
    match: '/account/spending-buckets/(.*)',
    target: 'next',
  },
  {
    match: '/bank/accounts/spending-buckets/list',
    target: 'next',
  },
  {
    match: '/status-tracker',
    target: 'next',
  },
  {
    match: '/bank/manage-ira',
    target: 'next',
  },
  {
    match: '/bank/manage-ira/(.*)',
    target: 'next',
  },
  {
    match: '/bank/manage-cds',
    target: 'next',
  },
  {
    match: '/bank/manage-cds/(.*)',
    target: 'next',
  },
  {
    match: '/bank/secure-messages',
    target: 'next',
  },
  {
    match: '/bank/secure-messages/(.*)',
    target: 'next',
  },
  {
    match: '/investments/secure-messages',
    target: 'next',
  },
  {
    match: '/forgot-password/(.*)',
    target: 'next',
  },
  {
    match: '/forgot-username',
    target: 'next',
  },
  {
    match: '/forgot-username/(.*)',
    target: 'next',
  },
  {
    match: '/alerts',
    target: 'next',
  },
  {
    match: '/alerts/(.*)',
    target: 'next',
  },
  {
    match: '/gallery',
    target: 'next',
  },
  {
    match: '/sso/(.*)',
    target: 'next',
  },
  {
    match: '/debt-plan',
    target: 'next',
  },
  {
    match: '/debt-plan/(.*)',
    target: 'next',
  },
]

/**
 * Array of routes to use as a default when evaluating the 'react-routes' feature flag
 * This should be updated to match the current 'Production' variation when it changes
 * https://app.launchdarkly.com/default/production/features/react-routes/variations
 */
export const DEFAULT_EMBER_ROUTES = [
  {
    match: '/app-setup',
    target: 'ember',
  },
  {
    match: '/bank/accounts/disputes-redirect/(.*)?',
    target: 'ember',
  },
  {
    match: '/bank/certify-tin',
    target: 'ember',
  },
  {
    match: '/bank/dispute-transaction-summary/(.*)?',
    target: 'ember',
  },
  {
    match: '/bank/(.*)/dispute-transaction/(.*)?',
    target: 'ember',
  },
  {
    match: '/beneficiaries/(.*)?',
    target: 'ember',
  },
  {
    match: '/error/page-not-found',
    target: 'ember',
  },
  {
    match: '/error/system-unavailable',
    target: 'ember',
  },
  {
    match: '/open-account/(.*)?',
    target: 'ember',
  },
  {
    match: '/payments/deposit-checks/(.*)?',
    target: 'ember',
  },
  {
    match: '/payments/pay-people',
    target: 'ember',
  },
  {
    match: '/payments/transfers',
    target: 'ember',
  },
  {
    match: '/temporarily-unavailable',
    target: 'ember',
  },
]
