import { createContext, useContext } from 'react'
import { UpdateForm } from './index'

export interface ApplicationSettingsContextValues {
  updateForm: UpdateForm
}

export const ApplicationSettingsContext = createContext<ApplicationSettingsContextValues>(
  {} as ApplicationSettingsContextValues,
)

export const useApplicationSettings = (): ApplicationSettingsContextValues => {
  return useContext(ApplicationSettingsContext)
}
