import { noop } from '@ally/utilitarian'
import React, { useContext, useState } from 'react'
import { RemoteConfig } from '@ally/federated-types'

export interface RemoteNotificationsValues {
  setRemoteNotifications(remoteNotifications: RemoteConfig[]): void
  remoteNotifications: RemoteConfig[]
}

export const RemoteNotificationsContext = React.createContext<RemoteNotificationsValues>(
  { setRemoteNotifications: noop, remoteNotifications: [] },
)

export function useRemoteNotifications(): RemoteNotificationsValues {
  return useContext(RemoteNotificationsContext)
}

export const RemoteNotificationsProvider: React.FC = ({ children }) => {
  const [remoteNotifications, setRemoteNotifications] = useState<
    RemoteConfig[]
  >([])
  const contextValues = {
    setRemoteNotifications,
    remoteNotifications,
  }
  return (
    <RemoteNotificationsContext.Provider value={contextValues}>
      {children}
    </RemoteNotificationsContext.Provider>
  )
}
