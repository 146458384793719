import React from 'react'

import { TextBody } from '@ally/metronome-ui'

import { contentFederator, headerFederator } from '../../../federation'
import { InfoRow, InfoColumn, DebugPanelSubSection } from '../Shared'
import { RouteSchema } from './RouteSchema'

interface LocationInfoProps {
  className?: string
}

export const Location: React.FC<LocationInfoProps> = () => {
  const { mounted: contentApp } = contentFederator.stats.state
  const { mounted: headerApp } = headerFederator.stats.state

  const none = (
    <TextBody tag="span" size="sm">
      none
    </TextBody>
  )

  return (
    <DebugPanelSubSection title="Location">
      <InfoColumn>
        <InfoRow
          title="Mounted Header App"
          content={headerApp ? headerApp.id : none}
          type="Default"
        />
        <InfoRow
          title="Mounted Content App"
          content={contentApp ? contentApp.id : none}
          type="Default"
        />
      </InfoColumn>
      <RouteSchema />
    </DebugPanelSubSection>
  )
}

export default Location
