import React, { useState } from 'react'
import styled from 'styled-components'

import { Box } from '@ally/metronome-ui'
import { env } from '../../constants'

const StyledBox = styled(Box)(
  ({ theme: { space, colors, fontSizes } }) => `
    background-color: ${colors.toaster};
    border-radius: ${space.xxs};
    bottom: ${space.xs};
    color: ${colors.white};
    cursor: pointer;
    font-size: ${fontSizes.xs};
    font-weight: bold;
    right: ${space.xs};
    padding: ${space.xs} ${space.md};
    position: fixed;
    z-index: 999;
  `,
)

/**
 * A simple banner that informs the user that they're viewing the AllyNext host
 * app. Since we're hosting multiple react versions at the moment, this will
 * help clarify which app is being viewed for QA/POs, etc.
 */
const DevEnvironmentAwareness: React.FC = () => {
  const [isVisible, setVisibility] = useState(env.isDevLike)

  if (!isVisible) {
    return null
  }

  return (
    <StyledBox onClick={(): void => setVisibility(false)}>
      AllyNext ({env.name})
    </StyledBox>
  )
}

export default DevEnvironmentAwareness
