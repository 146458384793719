import React, { FC } from 'react'

import { Level } from '@ally/whisper'
import { Box, RadioButton, RadioGroup } from '@ally/metronome-ui'

import { logLevel } from '../../../constants'
import { UpdateFormResult } from './index'
import { useApplicationSettings } from './ApplicationSettingsContext'

const logLevelKey = 'WHISPER_LOG_LEVEL'

export function getAppLogLevel(): Level {
  const saved = localStorage.getItem(logLevelKey) as Level | null
  return saved || logLevel
}

function setAppLogLevel(
  previousValue: string,
  value: string,
): UpdateFormResult {
  if (previousValue === value) {
    return UpdateFormResult.Abort
  }

  localStorage.setItem(logLevelKey, value)
  return UpdateFormResult.Success
}

export const LogLevel: FC = () => {
  const { updateForm } = useApplicationSettings()
  const defaultValue = getAppLogLevel()

  return (
    <Box>
      <RadioGroup
        name="Log Level"
        legendText="Preferred log level"
        defaultValue={defaultValue}
        onChange={updateForm(value => setAppLogLevel(defaultValue, value))}
      >
        <RadioButton label="Error" value="error" />
        <RadioButton label="Warn" value="warn" />
        <RadioButton label="Info" value="info" />
        <RadioButton label="Debug" value="debug" />
        <RadioButton label="Trace" value="trace" />
      </RadioGroup>
    </Box>
  )
}

export default LogLevel
