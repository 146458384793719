import React, { useState, useContext, createContext } from 'react'
import {
  SubNav,
  SubNavSchema,
  SubNavLinkSchema,
  SubNavDropdownSchema,
  SubNavButtonSchema,
  SchemaClass,
} from '@ally/federated-types'

import { useGlobalNav } from '..'

const SubNavContext = createContext<SubNav>({} as SubNav)

export const useSubNav = (): SubNav => useContext(SubNavContext)

/**
 * The SubNavProvider.
 * Keeps track of the current navigation menu's links, buttons, and dropdowns.
 * Provides a set of memoized "actions" that are passed down through to the remote
 * application for setting/resetting the sub nav.
 *
 * Any changes made by remote applications will be reverted (reset) when the
 * child application unmounts.
 *
 * @example
 * // Example remote usage:
 * const { subnav: { set, schema, Schema } } = useHostServices()
 * const mySchema = new Schema(schema, sortOrder)
 * const newSchema = [
 * {
 *        type: 'link',
 *        content: 'Link Content',
 *        url: '/test',
 *        key: 'linkContent',
 *      },
 * ]
 * useEffect(() => set(mySchema.set(newSchema).schema, [])
 */

const sortSchema = (
  unsortedSchema: SubNavSchema,
  sortOrder: string[],
): SubNavSchema => {
  return [...unsortedSchema].sort((a, b) => {
    const aKeyIndex = sortOrder.indexOf(a.key)
    const bKeyIndex = sortOrder.indexOf(b.key)
    const isAMissing = aKeyIndex < 0
    const isBMissing = bKeyIndex < 0

    // if neither a nor b are in the sort array, don't sort them
    if (isAMissing && isBMissing) return 0
    // if a is missing from the sort array, but not b, sort a after b
    if (isAMissing) return 1
    // if a is present in the sort array, but b is missing, sort a before b
    if (isBMissing) return -1
    // if a comes before b in the sort array, sort it before b
    if (aKeyIndex < bKeyIndex) return -1
    // if a comes after in the sort array, sort it after b
    if (aKeyIndex > bKeyIndex) return 1
    // if none of the above conditions return true, don't sort them
    return 0
  })
}

type SchemaItem = SubNavLinkSchema | SubNavButtonSchema | SubNavDropdownSchema

const Schema: SchemaClass = class Schema {
  constructor(schema: SubNavSchema, sortOrder?: string[]) {
    this.schema = schema
    this.sortOrder = sortOrder || []
  }

  schema: SubNavSchema

  sortOrder: string[]

  set(schema: SubNavSchema): Schema {
    this.schema = sortSchema(schema, this.sortOrder)
    return this
  }

  add(partialSchema: SubNavSchema): Schema {
    const matches: number[] = []
    const oldPartial = this.schema.map(
      (item: SchemaItem): SchemaItem => {
        const match = partialSchema.findIndex(it => it.key === item.key)
        if (match < 0) return item
        matches.push(match)
        return partialSchema[match]
      },
    )
    const newFilteredPartial = partialSchema.filter(
      (item: SchemaItem, index: number): boolean => {
        const isAlreadyIncluded = matches.includes(index)
        return !isAlreadyIncluded
      },
    )
    const newCombined = [...oldPartial, ...newFilteredPartial]

    this.schema = sortSchema(newCombined, this.sortOrder)
    return this
  }

  remove(itemsToRemove: string[]): Schema {
    this.schema = this.schema.reduce(
      (acc: SubNavSchema, item): SubNavSchema => {
        if (itemsToRemove.includes(item.key)) return acc
        return [...acc, item]
      },
      [],
    )
    return this
  }

  reset(): Schema {
    this.schema = []
    return this
  }
}

export const SubNavProvider: React.FC = ({ children }) => {
  const [schema, set] = useState<SubNavSchema>([])
  const { isHidden } = useGlobalNav()

  return (
    <SubNavContext.Provider
      value={{ schema: isHidden ? [] : schema, set, Schema }}
    >
      {children}
    </SubNavContext.Provider>
  )
}
