import React, { FC, useState, useEffect } from 'react'

/**
 * Higher order component that inspects route before continuing.
 * If destination pattern matches browser router, then strip out
 * the "#" symbol. Legacy applications used hash routing, and the
 * purpose of this is to gracefully handle any edge cases like old
 * links, users bookmarks, etc.
 *
 * Example Usage...
 *
 * ```javascript
 * // User requests "https://example.com/#/profile/settings"
 * const ExampleApp = ({ children }) => (
 *  <RemoveHash>
 *    <div>
 *      <p>Rest of your application</p>
 *      <p>
 *        URL will be replaced with
 *        "https://example.com/profile/settings"
 *      </p>
 *      {children}
 *    </div>
 *  </RemoveHash>
 * )
 * ```
 */
export const RemoveHash: FC = ({ children }) => {
  const [allowed, setAllowed] = useState(false)

  const removeHash = (): void => {
    const { href, origin } = window.location
    const currentPath = href.replace(origin, '')

    if (currentPath.substring(0, 2) === '/#') {
      const newPath = currentPath.replace('/#', '')
      const newUrl = `${origin}${newPath}`
      window.history.replaceState({}, document.title, newUrl)
    }

    setAllowed(true)
  }
  useEffect(() => {
    removeHash()
  }, [])

  return allowed ? <>{children}</> : null
}

export default RemoveHash
