import React, { Dispatch, SetStateAction, useState } from 'react'
import { Checkbox } from '@ally/metronome-ui'

import log from '../../../whisper'
import { env } from '../../../constants'
import { UpdateFormResult } from './index'
import { useApplicationSettings } from './ApplicationSettingsContext'

import {
  isWarpgateEnabled,
  WarpgateDisableKey,
  WarpageDisableQueryParam,
} from '../../Warpgate'

const enable = (): void => {
  localStorage.removeItem(WarpgateDisableKey)
  sessionStorage.removeItem(WarpgateDisableKey)

  // Need to remove any ?disableWarpgate parameter otherwise the user will
  // refresh and it will disable warpgate all over again.
  const params = new URLSearchParams(window.location.search)
  params.delete(WarpageDisableQueryParam)

  const search = params.toString().length > 0 ? `?${params}` : ''
  const href = `${window.location.pathname}${search}`
  window.history.replaceState({}, document.title, href)
}

const disable = (): void => {
  localStorage.setItem(WarpgateDisableKey, 'true')
}

function setsWarpgateState(update: Dispatch<SetStateAction<boolean>>) {
  return (): UpdateFormResult => {
    const nextState = !isWarpgateEnabled()

    ;(nextState ? enable : disable)()
    update(nextState)

    log.info({
      message: `[Warpgate] ${nextState ? 'Enabled' : 'Disabled'}`,
    })

    return UpdateFormResult.Success
  }
}

const Warpgate: React.FC<{ className?: string }> = ({ className }) => {
  const { updateForm } = useApplicationSettings()
  const [isWGEnabled, setWarpgate] = useState(isWarpgateEnabled())

  return (
    <Checkbox
      label="Enable Warpgate"
      name="warpgate-enabled"
      onChange={updateForm(setsWarpgateState(setWarpgate))}
      isChecked={isWGEnabled}
      isDisabled={!env.isDevLike}
      className={className}
    />
  )
}

export default Warpgate
