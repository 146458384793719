import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useGlobalLocation } from '@ally/federator'

interface QueryParams {
  isWebWrapped?: boolean
}

/**
 * Returns wether the Host App is currently being "Web wrapped" in the mobile application. Defaults to `false`
 *
 * The mobile app can communicate this by adding a `isWebWrapped=true` query param to the URL
 *
 * The app will stay in it's "web wrapped" state until it is reloaded
 */
export function useIsWebWrapped(): boolean {
  const location = useGlobalLocation()
  const [isWebWrapped, setIsWebWrapped] = useState(false)

  useEffect(() => {
    const params = queryString.parse(location.search, { parseBooleans: true })
    const { isWebWrapped: queryValue = false }: QueryParams = params

    /**
     * Sets "isWebWrapped" to `true` until the user reloads the app. Prevents navigation (and removal of query params) from resetting this value
     */
    if (queryValue) {
      setIsWebWrapped(true)
    }
  }, [location.search])

  return isWebWrapped
}
