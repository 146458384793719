import React, { FC, BaseSyntheticEvent } from 'react'
import { CheckboxStateful } from '@ally/metronome-ui'

import { useApplicationSettings } from './ApplicationSettingsContext'
import { UpdateFormResult } from './index'
import { logScrubbingLSKey, logScrubbingEnabled } from '../../../constants'

const enable = (): void => localStorage.setItem(logScrubbingLSKey, 'true')
const disable = (): void => localStorage.setItem(logScrubbingLSKey, 'false')

function handleLogScrubbingUpdate({
  target: { checked },
}: BaseSyntheticEvent): UpdateFormResult {
  ;(checked ? enable : disable)()
  return UpdateFormResult.Success
}

const LogScrubbing: FC<{ className?: string }> = ({ className }) => {
  const { updateForm } = useApplicationSettings()

  return (
    <CheckboxStateful
      name="log-scrubbing-enabled"
      label="Enable log scrubbing"
      onChange={updateForm(handleLogScrubbingUpdate)}
      defaultChecked={logScrubbingEnabled}
      className={className}
    />
  )
}

export default LogScrubbing
