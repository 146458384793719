import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react'

import { once } from '@ally/utilitarian'
import { useHistory } from 'react-router'
import { ParsedQuery } from 'query-string'
import { BaseSession } from '@ally/federated-types'
import { checkAndHandleInterrupts } from '@ally/transmitigator'

import log from '../../whisper'
import * as tasks from './tasks'

import { env } from '../../constants'
import { Routes } from './types'
import { useSession } from '../session'
import { FeatureFlagClients } from './tasks/features'
import { track, TrackingEvent } from '../../tracking'
import { useTransmitRef } from '..'

export interface BootstrapServices {
  query: ParsedQuery<string>
  featureFlags: FeatureFlagClients
  fallbackRoutes: Promise<Routes>
}

export interface Bootstrap {
  services: BootstrapServices | null
  isBootstrapping: boolean
}

interface BootstrappedServices extends BootstrapServices {
  session: BaseSession
}

export const BootstrapContext = createContext<Bootstrap>({} as Bootstrap)
export const useBootstrap = (): Bootstrap => useContext(BootstrapContext)

/**
 * Invokes all of the bootstrapping tasks.
 *
 * If rehydration was successful:
 * - Associate the user with LaunchDarkly.
 *   This will bind any flags set on the anonymous user to the logged in user.
 * - Start the call for the fallback routes file. This is fire and forget and
 *   won't block the render unless LD fails.
 */
export const bootstrap = once(
  async (
    rehydrate: () => Promise<BaseSession>,
  ): Promise<BootstrappedServices> => {
    log.info({ message: 'Bootstrapping...' })

    const { release } = APP_BUILD_INFO
    track(TrackingEvent.HostInit, { release })
    track(`${TrackingEvent.HostInit}-${env.name}`)
    track(TrackingEvent.BootstrapInit)

    if (env.prod.C) track(TrackingEvent.HostSideC)
    if (env.prod.D) track(TrackingEvent.HostSideD)

    const fallbackRoutes = tasks.getFallbackRoutes()

    const [query, session, featureFlags] = await Promise.all([
      tasks.query(),
      rehydrate(),
      tasks.features(),
    ])

    track(TrackingEvent.BootstrapDone)

    return {
      query,
      session,
      featureFlags,
      fallbackRoutes,
    }
  },
)

/**
 * The Bootstrap Provider.
 * Initializes all services necessary prior to rendering anything EXCEPT global
 * header/footer components and perhaps a loading indicator.
 *
 * This provider will invoke the `bootstrap` function, setting up (and awaiting)
 * the initialization of the following services:
 *
 * - LaunchDarkly (featureFlags)
 * - Install the ServiceWorker
 * - Attempt to rehydrate any active user session
 *
 * Once initialization is complete, if the user is logged in, the session will
 * bet set (updated) and the services will be available downstream. If
 * bootstrapping fails, the application cannot continue, so the user will be
 * redirected to the apocalyptic "temporarily unavailable" error page.
 */
export const BootstrapProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const { setSession } = useSession()
  const { transmitRef } = useTransmitRef()
  const transmit = transmitRef.useTransmit()

  const sessionSetRef = useRef(false)
  const [value, setState] = useState<Bootstrap>({
    services: null,
    isBootstrapping: true,
  })

  const rehydrate = tasks.useRehydrate()

  useEffect(() => {
    bootstrap(rehydrate)
      .then(({ session, ...services }) => {
        setState({ services, isBootstrapping: false })

        transmit.setFeatureFlags(services.featureFlags.bank.client.allFlags())

        // Should this component re-mount for any reason, then *don't* clobber
        // any session that was set by a remote after initial rehydration.
        if (!sessionSetRef.current) {
          sessionSetRef.current = true
          setSession(session)

          if (session) {
            // This can result in navigation and should be called last
            checkAndHandleInterrupts(
              session.data,
              history.push,
              services.featureFlags.bank.client.variation,
            )
          }
        }
      })
      .catch(e => {
        history.replace('/error')
        setState({ services: null, isBootstrapping: false })
        log.error({ message: ['Bootstrap Failure:', e.stack] })
      })
  }, [history, setSession])

  return (
    <BootstrapContext.Provider value={value}>
      {children}
    </BootstrapContext.Provider>
  )
}
