import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { FormText } from '@ally/metronome-ui'

import { idleSessionExpiresSec } from '../../../constants'
import { useApplicationSettings } from './ApplicationSettingsContext'
import { UpdateFormResult } from './index'

export const sessionTimeoutKey = 'IDLE_SESSION_TIMEOUT'

function setSessionTimeout(value: string): void {
  localStorage.setItem(sessionTimeoutKey, value)
}

function updateSessionTimeout(update: Dispatch<SetStateAction<number>>) {
  return ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): UpdateFormResult => {
    setSessionTimeout(value)

    update(Number(value))

    return UpdateFormResult.Success
  }
}

export const SessionTimeout: React.FC = () => {
  const { updateForm } = useApplicationSettings()
  const [sessionTimeoutValue, setSessionTimeoutValue] = React.useState(
    idleSessionExpiresSec,
  )

  return (
    <FormText
      id="sessionTimeout"
      variant="input"
      inputType="number"
      labelContent="Session timeout duration"
      value={sessionTimeoutValue.toString()}
      aria-label="Session timeout duration in seconds"
      onChange={updateForm(updateSessionTimeout(setSessionTimeoutValue))}
      adornment={{
        variant: 'custom',
        position: 'right',
        item: {
          variant: 'static',
          text: 'sec',
        },
      }}
      contextualHelp={{
        variant: 'stacked',
        content:
          'The amount of time before the host app will end your session.',
      }}
    />
  )
}

export default SessionTimeout
