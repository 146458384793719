import log from '../../../whisper'

import { Routes } from '../types'
import { fallbackRoutesURL } from '../../../constants'

import {
  track,
  TrackingEvent,
  withTrackingEventsAsync,
} from '../../../tracking'

function handleFallbackFetchFailure(e: Error): Routes {
  track(TrackingEvent.FallbackRoutesError)
  log.error({ message: ['Failed loading static fallback routes:', e.stack] })
  return {}
}

function handleFallbackRoutesResponse(response: Response): Promise<Routes> {
  if (response.ok) return response.json()
  throw new Error(`[${response.status}] ${response.statusText}`)
}

function handleFallbackRoutesSuccess(routes: Routes): Routes {
  log.info({ message: 'Fallback routes loaded successfully.' })
  return routes
}

/**
 * Loads fallback routes.
 * We're making this call each time for awareness' sake. If this file isn't
 * retrievable we need to know about it. So, even if LD is working fine, this
 * will be fetched.
 */
export function getStaticFallbackRoutes(): Promise<Routes> {
  return fetch(fallbackRoutesURL)
    .then(handleFallbackRoutesResponse)
    .then(handleFallbackRoutesSuccess)
    .catch(handleFallbackFetchFailure)
}

export default withTrackingEventsAsync(getStaticFallbackRoutes, [
  TrackingEvent.FallbackRoutesInit,
  TrackingEvent.FallbackRoutesDone,
])
