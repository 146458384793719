import React from 'react'
import styled from 'styled-components'

import { AppSchema } from '@ally/federator'

import {
  setJSONLocalStorage,
  getJSONSessionStorage,
  setJSONSessionStorage,
} from '@ally/utilitarian'

import { Box, TextBody, Icon, Space, Button } from '@ally/metronome-ui'
import { deprecated } from '@ally/metronome-icons'

import {
  env,
  devRoutesStorageKey,
  isDevRoutesBannerHiddenKey,
} from '../../constants'

interface DevRouteAwarenessProps {
  remotes: Record<string, AppSchema & { isDevRoute?: boolean }>
  isLoading: boolean
}

const StyledBox = styled(Box)(
  ({ theme: { colors, space } }): string => `
    padding: ${space.md} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.black};
    opacity: 0.6;
    background-color: ${colors.cc};
    overflow: hidden;
  `,
)

const getIsUsingDevRoute = (
  remotes: Record<string, AppSchema & { isDevRoute?: boolean }>,
): boolean => {
  return Object.entries(remotes).some(([key, { isDevRoute }]) => {
    if (key === 'ally-next-remote-boilerplate') {
      return false
    }
    return isDevRoute
  })
}

const DevRouteAwareness: React.FC<DevRouteAwarenessProps> = ({
  remotes,
  isLoading,
}) => {
  const isHidden = !!getJSONSessionStorage(isDevRoutesBannerHiddenKey)
  const [isDismissed, setIsDismissed] = React.useState<boolean>(isHidden)
  const isClosed = isLoading || isDismissed
  const isUsingDevRoute = getIsUsingDevRoute(remotes)

  const closeWarning = (): void => {
    setIsDismissed(true)
    setJSONSessionStorage(isDevRoutesBannerHiddenKey, true)
  }

  const clearDevRoutes = (): void => {
    setIsDismissed(true)
    setJSONLocalStorage(devRoutesStorageKey, {})
    const { history, location } = window
    history.pushState({}, '', location.pathname)
  }

  const shouldDisplay =
    (env.isDevLike || env.isQA) && isUsingDevRoute && !isClosed

  if (!shouldDisplay) return null

  return (
    <StyledBox>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Box display="grid" gridTemplateColumns="1fr 3fr 1fr" width="100%">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gridColumn="2"
          >
            <Space mr="xxs">
              <Icon
                icon={deprecated.muiWarningFill}
                size="md"
                fill="slate-5"
                ariaHidden
              />
            </Space>
            <TextBody tag="p" size="md" weight="bold">
              Warning
            </TextBody>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gridColumn="3"
          >
            <Button
              variant="link"
              icon={{
                map: deprecated.muiClose,
              }}
              onClick={closeWarning}
              data-testid="awareness-banner-close-btn"
            />
          </Box>
        </Box>
        <Box>
          <TextBody tag="p" size="sm" weight="bold">
            Currently, there are routes overriding the default route schema.
          </TextBody>
          <TextBody tag="p" size="sm">
            For local and PR development, this is expected.
          </TextBody>
          <TextBody tag="p" size="sm">
            If you would like to reset to the default routes, click
            &quot;Reset&quot; below.
          </TextBody>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="xs"
          >
            <Space mr="xxs">
              <Icon
                icon={deprecated.muiInfoFill}
                size="sm"
                fill="black"
                ariaHidden
              />
            </Space>
            <TextBody tag="p" size="sm">
              If you need to reset the route for the currently loaded remote,
              reload your browser after clicking &quot;Reset&quot;.
            </TextBody>
          </Box>
          <TextBody tag="p" size="sm">
            Open the Application Info Drawer for more details (see
            ally-next-host README).
          </TextBody>
        </Box>
        <Space mt="xs">
          <Button variant="destructive" text="Reset" onClick={clearDevRoutes} />
        </Space>
      </Box>
    </StyledBox>
  )
}

export default DevRouteAwareness
