import { BaseSession } from '@ally/federated-types'
import { getTransmitTicket } from '@ally/transmitigator'
import { useEffect, useRef, useState } from 'react'
import { TrackingEvent, withTrackingEventsAsync } from '../../../tracking'
import { useTransmitRef } from '../../transmit-ref'

type RehydrateFn = () => Promise<BaseSession>

/**
 * Hook that returns a function that attempts to rehydrate the user's session.
 * If transmitigator.rehydrate deems no session, then `null` is resolved.
 * Otherwise, the session data is resolved.
 */
function useRehydrate(): RehydrateFn {
  const { transmitRef } = useTransmitRef()
  const transmitHook = transmitRef.useTransmit()

  const [resolver, setResolver] = useState<((arg: null) => void) | null>(null)

  useEffect(() => {
    if (resolver !== null) {
      resolver(null)
      setResolver(null)
    }
  }, [transmitHook.activeScreen.type])

  const { current: promiseFn } = useRef(
    withTrackingEventsAsync(async (): Promise<BaseSession> => {
      // In the case of rehydrating from Storefront with ticketId, if the session needs OTP the
      // rehydrate promise never resolves of rejects and leaves the host in a loading state.
      // To combat this, when we detect this scenario (ticketID starts with 'sf-nm') we add an additional
      // promise which resolves when the activeScreen changes ('Unauthenticated' => 'SendCode')
      // Ideally this would be moved to transmitigator when possible
      const useFallback = /^sf-nm|au-mc|pr-/.test(getTransmitTicket() ?? '')
      const fallbackPromises = useFallback
        ? [new Promise<null>(res => setResolver(() => res))]
        : []
      const data = await Promise.race([
        transmitHook.actions.rehydrate({ journey_version: 'v2' }),
        ...fallbackPromises,
      ])

      // If rehydration fails, reset the SSO FDIC Interstitial Accepted session value
      // This will allow the FDIC Interstitial to be shown again when a user clicks on Invest/Wealth/OLA SSO links
      if (!data) {
        window.sessionStorage.setItem('FDIC_Interstitial_Accepted', 'false')
      }

      return {
        data: data?.type === 'session' ? data.session : null,
        status: data ? 'Authenticated' : 'Unauthenticated',
        destroy: transmitHook.actions.logout,
      }
    }, [TrackingEvent.RehydrateInit, TrackingEvent.RehydrateDone]),
  )

  return promiseFn
}

export default useRehydrate
