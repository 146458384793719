import { useEffect } from 'react'
import { useGlobalLocation } from '@ally/federator'
import { getJSONSessionStorage, setJSONSessionStorage } from '@ally/utilitarian'
import { sessionFirstDashboardVisitKey } from '../../constants'

type SessionStorageValues = {
  onLogout: () => void
}

const cleanupSessionState = (): void => {
  setJSONSessionStorage(sessionFirstDashboardVisitKey, false)
}

export const useSessionStorage = (): SessionStorageValues => {
  const { pathname } = useGlobalLocation()

  useEffect(() => {
    const hasVisitedDashboard =
      getJSONSessionStorage<boolean>(sessionFirstDashboardVisitKey) !== null

    const isDashboardRoute = pathname.startsWith('/dashboard')

    if (isDashboardRoute && !hasVisitedDashboard) {
      window.addEventListener('beforeunload', cleanupSessionState)
      setJSONSessionStorage(sessionFirstDashboardVisitKey, true)
    } else if (!isDashboardRoute && hasVisitedDashboard) {
      setJSONSessionStorage(sessionFirstDashboardVisitKey, false)
    }
  }, [pathname])

  const onLogout = (): void => {
    window.sessionStorage.removeItem(sessionFirstDashboardVisitKey)

    // this key is set by the dashboard remote
    // we clean it up for them on logout
    window.sessionStorage.removeItem('hasVisitedDashboard')
  }

  return { onLogout }
}
