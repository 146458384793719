import { size } from '@ally/utilitarian'
import { useContext } from 'react'
import { UtilsContext, UtilsContextValue } from './UtilsProvider'

export default (): UtilsContextValue => {
  const context = useContext(UtilsContext)

  if (!size(context)) {
    throw new Error(
      'UtilsContext is empty, make sure you are calling useUtils as a child of <UtilsProvider />',
    )
  }

  return context
}
