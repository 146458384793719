// -------------------------------------------------------------------------- //
// WARNING: THIS CONTENT IS DUPLICATED IN `src/layout/print/PrintFooter.tsx`
// IF YOU CHANGE IT HERE, YOU PROBABLY SHOULD CHANGE IT THERE...
// -------------------------------------------------------------------------- //

import { deprecated } from '@ally/metronome-icons'
import { Icon, Space } from '@ally/metronome-ui'
import * as React from 'react'
import { FeatureFlag } from '../../../hooks'
import { useBootstrap } from '../../../providers'
import { DisclosuresText } from './shared'

const BankDisclosure: React.FC = () => {
  const { services } = useBootstrap()

  const featureFlags = {
    isCreditCardEnabled: services?.featureFlags.bank.client.variation(
      FeatureFlag.CreditCardEnabled,
      false,
    ),
  }

  return (
    <>
      <div data-testid="bank-accordion-content">
        <Space mb="sm">
          <DisclosuresText tag="p" size="sm" tabIndex={-1}>
            Ally Financial Inc. (NYSE: ALLY) is a leading digital financial
            services company. Ally Bank, the company&apos;s direct banking
            subsidiary, offers an array of deposit and mortgage products and
            services. Ally Bank is a Member FDIC and&nbsp;
            <DisclosuresText tag="span" size="sm" weight="bold">
              Equal Housing Lender&nbsp;
              <Icon
                icon={deprecated.muiEqualHousing}
                size="sm"
                fill="slate-4"
                ariaHidden
              />
            </DisclosuresText>
            &nbsp;NMLS ID 181005. Mortgage credit and collateral are subject to
            approval and additional terms and conditions apply. Programs, rates
            and terms conditions are subject to change at any time without
            notice.
          </DisclosuresText>

          {featureFlags.isCreditCardEnabled && (
            <DisclosuresText tag="p" size="sm">
              Ally Mastercard<sup>&reg;</sup> is issued by Ally Bank, Member
              FDIC under license from Mastercard<sup>&reg;</sup> International.
              FICO
              <sup>&reg;</sup> is a registered trademark of the Fair Isaac
              Corporation in the United States and other countries.
            </DisclosuresText>
          )}

          <DisclosuresText tag="p" size="sm">
            App Store is a service mark of Apple Inc. Google Play is a trademark
            of Google Inc. Amazon Appstore is a trademark of Amazon.com, Inc.,
            or its affiliates. Windows Store is a trademark of the Microsoft
            group of companies.
          </DisclosuresText>

          <DisclosuresText tag="p" size="sm">
            Zelle<sup>&reg;</sup> and the Zelle<sup>&reg;</sup> related marks
            are wholly owned by Early Warning Services, LLC and are used herein
            under license.
          </DisclosuresText>

          <DisclosuresText tag="p" size="sm">
            From MONEY. © 2020 Ad Practitioners, LLC. All rights reserved. Used
            under license.
          </DisclosuresText>
        </Space>
      </div>
    </>
  )
}

export { BankDisclosure }
