import * as React from 'react'
import {
  Box,
  Button,
  Space,
  TextBody,
  TextHeading,
  useModalContext,
} from '@ally/metronome-ui'

type ConfirmNavigationModalProps = {
  url: string
}

const ConfirmNavigationModal: React.FC<ConfirmNavigationModalProps> = ({
  url,
}) => {
  const { setOpen } = useModalContext()
  const handleContinue = (): void => {
    setOpen(false)
    window.open(url, '_blank')
  }

  const handleCancel = (): void => {
    setOpen(false)
  }

  return (
    <Box>
      <TextHeading tag="h1" size="md" focusOnMount>
        Important Notice
      </TextHeading>
      <Space my="sm">
        <TextBody tag="p" size="md" weight="bold">
          You&apos;re leaving ally.com
        </TextBody>
      </Space>
      <TextBody tag="p" size="sm">
        Selecting{' '}
        <TextBody tag="span" size="sm" weight="bold">
          Continue
        </TextBody>{' '}
        will take you to a website that Ally does not own or control. We are not
        responsible for the products, services or information you may find or
        provide there. Also, you should read and understand how that site&apos;s
        privacy policy, level of security and terms and conditions may impact
        you.
      </TextBody>
      <Space my="sm" mr="xs">
        <Button
          variant="primary"
          size="sm"
          text="Continue"
          onClick={(): void => handleContinue()}
          data-testid="confirm-leave-page"
          disableFullWidthSmDown
        />
        <Button
          variant="secondary"
          size="sm"
          text="Cancel"
          onClick={(): void => handleCancel()}
          disableFullWidthSmDown
        />
      </Space>
    </Box>
  )
}

export { ConfirmNavigationModal }
