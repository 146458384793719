import { thunk, flow } from '@ally/utilitarian'

type Status<T> = 'IDLE' | 'RUNNING' | T
type StatusUpdateCallback<T> = (status: Status<T>) => void
type TimeoutHandler<T> = (config: Config<T>, status: Status<T>) => () => void

export interface EventOptions<T> {
  delay: number
  status: Status<T>
}

export interface Options<T> {
  events: EventOptions<T>[]
}

interface Actions {
  clear: VoidFunction
  reset: VoidFunction
  start: VoidFunction
}

interface Config<T> extends Options<T> {
  handler: TimeoutHandler<T>
  timeouts: Map<Status<T>, number>
}

/**
 * Since node types are in here, TS thinks setTimeout is returning a
 * NodeJS.Timeout object. This fixes that.
 */
const timeout = (n: number, f: () => unknown): number => {
  return (setTimeout(f, n) as unknown) as number
}

/**
 * Clears all registered timeouts.
 */
function clears<T>({ timeouts }: Config<T>) {
  return (): void => {
    timeouts.forEach(clearTimeout)
    timeouts.clear()
  }
}

/**
 * Resets the expiration times for each timeout event.
 * Rather than setting a new timeout handler each time user activity occurs,
 * instead we're keeping timestamps of event expiration then waiting for the
 * current timeout to expire. If the expiration timestamp is still good, we set
 * a new timeout, otherwise we dispatch the event status update.
 */
function resets<T>(config: Config<T>) {
  return (): void => {
    clears(config)()
    const { events, handler, timeouts } = config

    events.forEach(({ delay, status }) => {
      timeouts.set(status, timeout(delay, handler(config, status)))
    })
  }
}

/**
 * Manages "Timeout Events".
 * Given a set of events that contain a `delay` and `status`. This will dispatch
 * status updated for each event after `delay` ms.
 *
 * Provides methods for starting, clearing, and resetting the timeouts:
 * clear - Clears all timeouts.
 * start - Creates the timeouts.
 * reset - Clears and resets timeouts.
 */
export function TimeoutManager<T>({ events }: Options<T>) {
  return (onStatusUpdate: StatusUpdateCallback<T>): Actions => {
    const timeouts = new Map<Status<T>, number>()
    const dispatches = thunk(onStatusUpdate)

    const handler = (_: Config<T>, status: Status<T>) => {
      return (): void => onStatusUpdate(status)
    }

    const config = {
      events,
      handler,
      timeouts,
    }

    return {
      clear: flow(clears(config), dispatches('IDLE')),
      start: flow(resets(config), dispatches('RUNNING')),
      reset: flow(resets(config), dispatches('RUNNING')),
    }
  }
}
