import React, { createContext, useState } from 'react'
import { Utils } from '@ally/federated-types'
import { HelpModalWithoutTrigger } from '@ally/shared-help-modal'
import { useLobs } from '../../hooks/use-lobs'
import { FeatureFlag, useFeatureFlag } from '../../hooks/use-feature-flag'

export type UtilsContextValue = Utils

const UtilsContext = createContext<UtilsContextValue>({} as UtilsContextValue)

const UtilsProvider: React.FC = ({ children }) => {
  const [helpModalState, setHelpModalState] = useState<Utils['helpModalState']>(
    {
      status: 'Closed',
      selectedCategory: null,
    },
  )

  const openHelpModal = (category?: string | number): void => {
    setHelpModalState({
      status: 'Open',
      selectedCategory: category ? category.toString() : null,
    })
  }

  const { bank: hasBankRelationship } = useLobs()
  const service = 'YEXT'
  const AosFaqLob = useFeatureFlag(FeatureFlag.AosFaqLob, 'spog')
  const DebounceLimit = useFeatureFlag(FeatureFlag.DebounceLimit, 300)

  return (
    <UtilsContext.Provider
      value={{
        openHelpModal,
        setHelpModalState,
        helpModalState,
      }}
    >
      <HelpModalWithoutTrigger
        service={service}
        lob={AosFaqLob}
        showCoBrowse={hasBankRelationship}
        open={helpModalState.status === 'Open'}
        defaultCategory={helpModalState.selectedCategory?.toString()}
        onOpenChange={(modalState: boolean): void =>
          setHelpModalState({
            ...helpModalState,
            status: modalState ? 'Open' : 'Closed',
          })
        }
        debounceLimit={DebounceLimit}
      />
      {children}
    </UtilsContext.Provider>
  )
}

export { UtilsContext, UtilsProvider }
