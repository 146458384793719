import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Box } from '@ally/metronome-ui'

import { useAnalytics } from '../providers'

import { Footer, WelcomeToAlly } from '../components'

import { PrintFooter } from './print/PrintFooter'

import { aboveAppPortalRef } from '../portal-refs'
import { useIsWebWrapped } from '../hooks'

interface LayoutProps {
  className?: string
}

const LayoutContainer = styled(Box)`
  @media print {
    /* NOTE: Adding display: block to print styles to avoid text cut off at page break. */
    display: block !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      page-break-after: avoid;
    }
  }
`

const Layout: React.FC<LayoutProps> = ({ children, className }) => {
  const { mountDigitalDataObject } = useAnalytics()

  /** load Adobe Launch lib before any page content loads */
  useEffect(mountDigitalDataObject, [])

  const isWebWrapped = useIsWebWrapped()

  return (
    <LayoutContainer className={className}>
      <WelcomeToAlly />
      {!isWebWrapped && (
        <Box id="above-app-portal" overflow="auto" ref={aboveAppPortalRef} />
      )}
      {children}
      {!isWebWrapped && (
        <>
          <Footer />
          <PrintFooter />
          <Box id="below-app-portal" overflow="auto" />
        </>
      )}
    </LayoutContainer>
  )
}

export const GlobalLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
