import {
  negate,
  getBooleanLocalStorage,
  getBooleanSessionStorage,
} from '@ally/utilitarian'

import log from '../../whisper'
import { env } from '../../constants'
import { query } from '../../providers/bootstrap/tasks'

export const app = 'next'
document.cookie = `wg=${app}; path=/; secure;`

export const WarpgateDisableKey = 'DISABLE_WARPGATE'
export const WarpageDisableQueryParam = 'disableWarpgate'

export function inspectWGQueryParam(): boolean {
  if (query()[WarpageDisableQueryParam] !== 'true') return true
  sessionStorage.setItem(WarpgateDisableKey, 'true')
  return false
}

/**
 * Determines if warpgate is disabled.
 * Warpgate can be disabled a number of ways:
 * 1. For local/dev only: by setting the DISABLE_WARPGATE local storage value.
 * 2. For local/dev only: by setting the DISABLE_WARPGATE session storage value.
 * 3. For local/dev only: by passing ?disableWarpgate=true.
 * 4. By passing DISABLE_WARPGATE === 'TRUE' at build time.
 */
export function isWarpgateDisabled(): boolean {
  if (!env.isDevLike) return process.env.DISABLE_WARPGATE === 'TRUE'

  inspectWGQueryParam()
  const hasLSDisable = getBooleanLocalStorage(WarpgateDisableKey)
  const hasSSDisable = getBooleanSessionStorage(WarpgateDisableKey)

  return process.env.DISABLE_WARPGATE === 'TRUE' || hasLSDisable || hasSSDisable
}

export const isWarpgateEnabled = negate(isWarpgateDisabled)

log.info({
  message: `[Warpgate] ${isWarpgateEnabled() ? 'Enabled' : 'Disabled'}`,
})
