import React, { FC, useState } from 'react'
import { ProdSideKey } from '@ally/which-env'
import { Box, RadioButton, RadioGroup } from '@ally/metronome-ui'
import { useApplicationSettings } from './ApplicationSettingsContext'
import { UpdateFormResult } from './index'

type ProdSwitcherValue = 'C' | 'D' | 'N/A'

const getCookieSnippet = (value: ProdSwitcherValue): string => {
  switch (value) {
    case 'C':
      return ProdSideKey.C
    case 'D':
      return ProdSideKey.D
    default:
      return '; expires=Thu, 01 Jan 1970 00:00:00 UTC'
  }
}

function setProdSwitcherCookie(
  defaultValue: string,
  value: string,
  setRadioValue: React.Dispatch<React.SetStateAction<ProdSwitcherValue>>,
): UpdateFormResult {
  if (defaultValue === value) {
    return UpdateFormResult.Abort
  }

  setRadioValue(value as ProdSwitcherValue)
  document.cookie = `pr_session=${getCookieSnippet(
    value as ProdSwitcherValue,
  )};`

  return UpdateFormResult.Success
}

export const getProdCookie = (): string | null => {
  const prodCookie = document.cookie
    .split(';')
    .find(cookie => cookie.match(/(?:pr_session=)/gi))

  if (!prodCookie) {
    return null
  }

  return prodCookie.trim()
}

const getRadioValue = (maybeProdCookie: string | null): ProdSwitcherValue => {
  if (maybeProdCookie?.includes(ProdSideKey.C)) {
    return 'C'
  }

  if (maybeProdCookie?.includes(ProdSideKey.D)) {
    return 'D'
  }

  return 'N/A'
}

export const ProdSwitcher: FC = () => {
  const [radioValue, setRadioValue] = useState(getRadioValue(getProdCookie()))

  const { updateForm } = useApplicationSettings()

  return (
    <Box>
      <RadioGroup
        name="Prod Side"
        legendText="Prod side"
        defaultValue={radioValue}
        onChange={updateForm(value =>
          setProdSwitcherCookie(radioValue, value, setRadioValue),
        )}
      >
        <RadioButton label="Default" value="N/A" />
        <RadioButton label="Prod C" value="C" />
        <RadioButton label="Prod D" value="D" />
      </RadioGroup>
    </Box>
  )
}

export default ProdSwitcher
