import * as React from 'react'
import { Accordion, Box, Icon, Space } from '@ally/metronome-ui'
import { deprecated } from '@ally/metronome-icons'
import styled from 'styled-components'
import { InvestDisclosure } from './InvestDisclosure'
import { BankDisclosure } from './BankDisclosure'
import { DisclosuresText } from './shared'
import { AutoDisclosure } from './AutoDisclosure'

const DisclosureSection: React.FC = () => {
  const currentYear = new Date().getFullYear()
  const StyledAccordion = styled(Accordion)`
    position: relative;
  `
  return (
    <Box as="section">
      <Box data-testid="footer-disclosures">
        <Space mb="sm">
          <DisclosuresText tag="p" size="sm">
            A FEW THINGS YOU SHOULD KNOW
          </DisclosuresText>
        </Space>
        <InvestDisclosure />
      </Box>

      <DisclosuresText tag="p" size="sm">
        Deposit and mortgage products are provided by Ally Bank, a Member FDIC
        and Equal Housing Lender{' '}
        <Icon
          icon={deprecated.muiEqualHousing}
          size="sm"
          fill="slate-4"
          ariaHidden
        />{' '}
        , NMLS ID 181005.
      </DisclosuresText>
      <StyledAccordion
        disableContainer
        items={[
          {
            heading: 'View Ally Bank Disclosures',
            panel: <BankDisclosure />,
          },
        ]}
      />

      <AutoDisclosure />

      <DisclosuresText tag="p" size="sm">
        &copy;2009 – {currentYear} Ally Financial, Inc.
      </DisclosuresText>
    </Box>
  )
}

export { DisclosureSection }
