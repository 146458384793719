// -------------------------------------------------------------------------- //
// WARNING: THIS CONTENT IS DUPLICATED IN `src/layout/print/PrintFooter.tsx`
// IF YOU CHANGE IT HERE, YOU PROBABLY SHOULD CHANGE IT THERE...
// -------------------------------------------------------------------------- //

import * as React from 'react'
import styled from 'styled-components'
import {
  Button,
  Link as MuiLink,
  Modal,
  Space,
  useModalContext,
} from '@ally/metronome-ui'
import { ConfirmNavigationModal } from '../Modals'
import { DisclosuresText } from './shared'

const StyledButton = styled(Button)`
  white-space: normal;
  text-align: initial;
`

type TriggerProps = {
  content: string
}

const Trigger: React.FC<TriggerProps> = ({ content }) => {
  const { setOpen } = useModalContext()
  return (
    <StyledButton
      variant="link"
      text={content}
      data-track-name={content}
      onClick={(): void => setOpen(true)}
    />
  )
}

const InvestDisclosure: React.FC = () => {
  return (
    <div data-testid="invest-accordion-content">
      <Space mb="xs">
        <DisclosuresText tag="p" size="sm">
          Securities products and services are offered through Ally Invest
          Securities LLC, member{' '}
          <Modal
            trigger={<Trigger content="FINRA" />}
            content={<ConfirmNavigationModal url="http://www.finra.org" />}
            height="auto"
            data-track-name="FINRA modal"
            data-track-elem="modal"
          />
          <span aria-hidden="true">/</span>
          <Modal
            trigger={<Trigger content="SIPC" />}
            content={<ConfirmNavigationModal url="http://www.sipc.org" />}
            height="auto"
            data-track-name="SIPC modal"
            data-track-elem="modal"
          />
          . For background on Ally Invest Securities go to{' '}
          <Modal
            trigger={<Trigger content="FINRA’s BrokerCheck" />}
            content={
              <ConfirmNavigationModal url="https://brokercheck.finra.org/firm/summary/136131" />
            }
            height="auto"
            data-track-name="FINRA BrokerCheck modal"
            data-track-elem="modal"
          />
          . Advisory services are offered through Ally Invest Advisors Inc., a
          registered investment adviser. Ally Bank, Ally Invest Advisors, and
          Ally Invest Securities are wholly owned subsidiaries of Ally Financial
          Inc.{' '}
          <MuiLink text="View disclosures">
            <a
              href="https://www.ally.com/invest/disclosures/"
              data-track-name="InvestDisclosure"
              target="_blank"
              rel="noreferrer"
            >
              content replaced by metronome
            </a>
          </MuiLink>
          . Securities products are{' '}
          <DisclosuresText tag="span" weight="bold" size="sm">
            NOT FDIC INSURED
          </DisclosuresText>
          ,{' '}
          <DisclosuresText tag="span" weight="bold" size="sm">
            NOT BANK GUARANTEED
          </DisclosuresText>
          , and{' '}
          <DisclosuresText tag="span" weight="bold" size="sm">
            MAY LOSE VALUE
          </DisclosuresText>
          .
        </DisclosuresText>
      </Space>

      <Space mb="xs">
        <DisclosuresText tag="p" size="sm">
          Options involve risk and are not suitable for all investors. Review
          the{' '}
          <Modal
            trigger={
              <Trigger
                content="Characteristics and Risks of Standardized
              Options brochure"
              />
            }
            content={
              <ConfirmNavigationModal url="https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document" />
            }
            height="auto"
            data-track-name="CharacteristicsAndRisksOfStandardizedOptionsBrochure"
            data-track-elem="modal"
          />{' '}
          before you begin trading options. Options investors may lose the
          entire amount of their investment or more in a relatively short period
          of time.
        </DisclosuresText>
      </Space>

      <Space mb="xs">
        <DisclosuresText tag="p" size="sm">
          Trading on margin involves risk. You can lose more funds than you
          deposit in a margin account. Please review{' '}
          <MuiLink
            text="Margin Account Agreement and Disclosure"
            data-track-name="MarginAccountAgreementAndDisclosure"
          >
            <a
              href="https://www.ally.com/invest/disclosures/margin-account-agreement-and-disclosure.html"
              target="_blank"
              rel="noreferrer"
            >
              content replaced by metronome
            </a>
          </MuiLink>{' '}
          (PDF) for more information regarding margin trading.
        </DisclosuresText>
      </Space>
    </div>
  )
}

export { InvestDisclosure }
