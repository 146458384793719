import React from 'react'
import { useHistory } from 'react-router-dom'

import { UserRegistrationStates } from '../hooks'
import { useAsyncFocus } from '../hooks/focus'
import { Route } from '../providers'
import { HeaderFallback } from './HeaderFallback'
import { RemoteAppContainer } from './RemoteAppContainer'
import { useHeaderFederation } from './use-federation'

export interface FederatedHeaderProps {
  userRegistrationStates: UserRegistrationStates
}

export const isHeaderRemote = ({ slot }: Route): boolean => slot === 'header'

export const FederatedHeader: React.FC<FederatedHeaderProps> = ({
  userRegistrationStates,
}) => {
  const history = useHistory()
  const focus = useAsyncFocus()
  const {
    federatedRef,
    isFederatedRoute,
    isTransitioning,
  } = useHeaderFederation({
    userRegistrationStates,
    remoteFilter: isHeaderRemote,
    onMounted: () => focus('#top-nav-ally-icon'),
    onRouteChangeError: () => history.replace('/error'),
  })

  const remoteLoaded = isFederatedRoute && !isTransitioning

  return (
    <header>
      {!remoteLoaded && <HeaderFallback />}
      <RemoteAppContainer
        ref={federatedRef}
        isLoading={!remoteLoaded}
        data-testid="header-remote-app-container"
      />
    </header>
  )
}
