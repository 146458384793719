import whichEnv from '@ally/which-env'
import { Level } from '@ally/whisper'
import { KeyCodeSchema } from '@ally/fauxnami'

import { has, getBooleanLocalStorage } from '@ally/utilitarian'

export const env = whichEnv(window.location.href)
export const host = process.env.APP_HOST_URL || window.location.origin
export const devRoutesKey = 'DEV_ROUTE_OVERRIDES'

const { max } = Math
const isTest = process.env.NODE_ENV === 'test'

// -------------------------------------------------------------------------- //
// Service Worker
// -------------------------------------------------------------------------- //

const forceLocalSW = getBooleanLocalStorage('FORCE_SW')
export const useServiceWorker = env.isLocal ? forceLocalSW : false

// -------------------------------------------------------------------------- //
// Logging
// -------------------------------------------------------------------------- //

export const logSilent = isTest
export const logLevel = env.pick<Level>({
  dev: 'debug',
  prod: 'info',
  local: 'debug',
})

// If true, ALL console logs will be scrubbed.
// This includes logs from third-party services.
// However, data will still propagate down to services like LaunchDarkly.
export const logScrubbingLSKey = 'ENABLE_LOG_SCRUBBING'
export const logScrubbingEnabled = has(localStorage, logScrubbingLSKey)
  ? getBooleanLocalStorage(logScrubbingLSKey)
  : env.isProd

// -------------------------------------------------------------------------- //
// LogRocket
// -------------------------------------------------------------------------- //

export const logRocketAppId = env.pick({
  dev: 'mvv2ld/aos-dev',
  cap: 'mvv2ld/aos-dev',
  fix: 'mvv2ld/aos-dev',
  qa1: 'mvv2ld/aos-dev',
  qa2: 'mvv2ld/aos-dev',
  qa3: 'mvv2ld/aos-dev',
  prod: 'mvv2ld/aos-prod',
  emulatorLLE: 'mvv2ld/aos-emulator-dev',
  emulatorProd: 'mvv2ld/aos-emulator',
})

export const logRocketIsEnabled = !env.isLocal

// -------------------------------------------------------------------------- //
// Dynatrace
// -------------------------------------------------------------------------- //

// App Name for Dynatrace
export const DYNATRACE_APP_NAME = env.isProd
  ? 'OneAllyWeb-Prod'
  : 'OneAllyWeb-NonProd'

// Control for Dynatrace RUM services...
export const dynatraceEnabled = true

// Control for Dynatrace Session Replay...
export const dynatraceSessionReplayEnabled = false

// -------------------------------------------------------------------------- //
// LaunchDarkly
// -------------------------------------------------------------------------- //

export const fallbackRoutesURL = '/ally-next-remotes/routes.json'
export const launchDarklyBankEnvKey = env.pick({
  dev: '5e179c1298f5e308e42d7a89',
  cap: '5e7272e85129c9071bdf08c1',
  fix: '5e7272e85129c9071bdf08c1',
  qa1: '5e7272e85129c9071bdf08c1',
  qa2: '5e7272e85129c9071bdf08c1',
  qa3: '5e7272e85129c9071bdf08c1',
  psp: '5e67ae7be7b2890a81545510',
  prod: '5e67ae7be7b2890a81545510',
  local: '5cae06c225ef5608100db5dc',
})

export const launchDarklyRoutingEnvKey = env.pick({
  dev: '5fcf957fe5e9d609fa7d4edc',
  cap: '601c1cc3b5ecf409ec01c8f3',
  fix: '601c1cc3b5ecf409ec01c8f3',
  qa1: '601c1cc3b5ecf409ec01c8f3',
  qa2: '601c1cc3b5ecf409ec01c8f3',
  qa3: '601c1cc3b5ecf409ec01c8f3',
  psp: '5fcf94ff90dd070a11cc44c7',
  prod: '5fcf94ff90dd070a11cc44c7',
  local: '5fcf957fe5e9d609fa7d4edc',
})

// From https://gitlab.com/allyfinancial/auto-insurance-products/aaos/ally-auto/-/blob/master/app/utils/external-urls.js#L100
export const launchDarklyAutoEnvKey = env.pick({
  local: '63125443c8369f10ae30a5d8',
  dev: '635bfbebee5b0b0c041d1135', // CIT2
  qa1: '635bfc0c0f4eb111cb345bd3', // UAT1
  qa2: '635bfbf986a86c0c4985c226', // UAT2
  qa3: '635bfbf986a86c0c4985c226', // UAT2
  cap: '636a9e36ecadef10dad93942',
  fix: '63125443c8369f10ae30a5d9',
  psp: '636a9e4e2a43601114f86f27',
  prod: '63125443c8369f10ae30a5d9',
})

// -------------------------------------------------------------------------- //
// Application Federation
// -------------------------------------------------------------------------- //

/* eslint-disable global-require */
// A list of external modules to expose to apps.
// NOTE: THIS SHOULD INCLUDE ONLY MAJOR MODULES AND MODULES THAT ARE *ALSO*
// USED BY THIS APPLICATION. DO NOT ABUSE THESE!
// The remote application can opt-in to using these by supplying them as
// externals in their webpack config... or they can choose their own version.
export const externals = new Map([
  ['logrocket', require('logrocket')],
  ['react', require('react')],
  ['react-dom', require('react-dom')],
  ['react-router-dom', require('react-router-dom')],
  ['styled-components', require('styled-components')],
])

export const devRoutesStorageKey = 'SAVED_DEV_ROUTE_OVERRIDES'

// -------------------------------------------------------------------------- //
// Ember Prefetch
// -------------------------------------------------------------------------- //

export const emberPrefetchDelay = 2000
export const emberPrefetchEnabled = env.pick({ prod: true, local: false })

// -------------------------------------------------------------------------- //
// Document
// -------------------------------------------------------------------------- //

export const documentTitle = 'Ally Online Services | Ally'
document.title = documentTitle

// -------------------------------------------------------------------------- //
// KeyCodes
// -------------------------------------------------------------------------- //

export const keyCodesLSKey = 'ENABLE_KEY_CODES'
export const keyCodesLSEnabled = getBooleanLocalStorage(keyCodesLSKey)

export const keyCodes: KeyCodeSchema = {
  debugPanel: {
    keys: 'up down up down left right left right b a',
  },
  featureFlagInfo: {
    keys: 'up down up down left right left right f f',
  },
}

// -------------------------------------------------------------------------- //
// Support contact numbers
// -------------------------------------------------------------------------- //

// NOTE: If these numbers contain the same keys for their respective target
// selecting the correct number can be simplified.
export const bankNumbers = {
  insideUS: '1-877-247-2559',
  outsideUS: '001-757-247-2559',
  fraud: '1-800-971-6037',
  telLink: 'tel:18772472559',
}

// -------------------------------------------------------------------------- //
// LivePerson
// -------------------------------------------------------------------------- //

const lpLoaderBaseUrl =
  'https://lpcdn.lpsnmedia.net/le_secure_storage/3.11.0.2-release_5036/storage.secure.min.js'

export const lpLoaderSrc = env.pick({
  dev: `${lpLoaderBaseUrl}?loc=https%3A%2F%2Fsecure-dev-ci.int.ally.com&site=52412050&force=1&env=dev`,
  cap: `${lpLoaderBaseUrl}?loc=https%3A%2F%2Fsecure-cap.ally.com&site=52412050&force=1&env=cap`,
  fix: `${lpLoaderBaseUrl}?loc=https%3A%2F%2Fsecure-fix.ally.com&site=52412050&force=1&env=fix`,
  qa1: `${lpLoaderBaseUrl}?loc=https%3A%2F%2Fsecure-qa1.ally.com&site=52412050&force=1&env=qa1`,
  qa2: `${lpLoaderBaseUrl}?loc=https%3A%2F%2Fsecure-qa1.ally.com&site=52412050&force=1&env=qa2`,
  qa3: `${lpLoaderBaseUrl}?loc=https%3A%2F%2Fsecure-qa1.ally.com&site=52412050&force=1&env=qa3`,
  prod: `${lpLoaderBaseUrl}?loc=https%3A%2F%2Fsecure.ally.com&site=52412050&force=1&env=prod`,
  local: `${lpLoaderBaseUrl}?loc=https%3A%2F%2Fsecure-dev-ci.int.ally.com&site=52412050&force=1&env=dev`,
})

// -------------------------------------------------------------------------- //
// Dev Routes Awareness Banner
// -------------------------------------------------------------------------- //

export const isDevRoutesBannerHiddenKey = 'DEV_ROUTES_BANNER_HIDDEN'

// -------------------------------------------------------------------------- //
// MR Gateway Awareness Notification
// -------------------------------------------------------------------------- //

export const hideIsMRGatewayUrl = 'HIDE_MR_GATEWAY_MESSAGE'

// -------------------------------------------------------------------------- //
// Apigee sts endpoint api keys
// -------------------------------------------------------------------------- //

export const apigeeAPIKey = env.pick({
  dev: 'wU5VdAI4fv7jgsaAOJDREixc3MOaUs1R',
  cap: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
  fix: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
  qa1: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
  qa2: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
  qa3: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
  prod: 'XhnbsDYmBensf2vqVXAfv0uD7SJBA27u',
  local: 'wU5VdAI4fv7jgsaAOJDREixc3MOaUs1R',
})

export const APIGEE_TOKEN_REFRESH_INTERVAL = (5 * 60 - 10) * 1000 // 10 sec before 5 minutes

// -------------------------------------------------------------------------- //
// Session/User/Transmit
// -------------------------------------------------------------------------- //

const FIFTEEN_MINUTES = 60 * 15

// The amount of time in seconds before the user's session is ended when no
// mouse or keyboard events occur. Mouse/keyboard events will reset this timer.
export const idleSessionExpiresSec = env.pick({
  prod: FIFTEEN_MINUTES,
  local:
    +(localStorage.getItem('IDLE_SESSION_TIMEOUT') || 0) || FIFTEEN_MINUTES,
})

export const idleSessionWarningSec = max(3, idleSessionExpiresSec - 60)
export const idleSessionResetEventNames = ['click', 'keydown', 'mousemove']

export const sessionFirstDashboardVisitKey = 'host-isFirstDashboardVisit'

const logoutURL = env.pick({
  prod: '/ext-storefront/logged-off/',
  local: 'https://www-dev1.int.ally.com/logged-off/',
})

// Redirects the user to this page when they logout.
// If not present or falsy, they'll be sent back to the login page.
export const logoutRedirectPage = isTest ? null : logoutURL

export const application = {
  id: 'ALLYUSBOLB',
  name: 'AOB',
  version: '1.0',
  apigeeApiKey: apigeeAPIKey,
}
