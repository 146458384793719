import { useEffect, useState } from 'react'

import cookies from 'js-cookie'

import { LoadState } from '@ally/use-data-domain'

import log from '../../whisper'
import { useSession } from '../../providers'
import getLogRocket from '../../services/logRocket/service'
import { useIsOnly } from '..'

export const SESSION_ID_COOKIE_NAME = 'TLTSID'

/**
 * Registers the user with LogRocket once all the necessary information is
 * available. To register a user with log rocket we need the user's guid and
 * username.
 */
export function useLRRegister(): LoadState {
  const [loadState, setRegistrationLoadState] = useState(LoadState.LOADING)

  const { data } = useSession()
  const guid = data?.guid
  const cif = data?.cif
  const investId = data?.investId
  const tpn = data?.tpn
  const isAutoOnly = useIsOnly('auto')
  const userId = isAutoOnly ? tpn : guid
  const name = data?.userNamePvtEncrypt

  useEffect(() => {
    if (loadState === LoadState.LOADED || !userId || !name) return

    getLogRocket().identify(userId, {
      name,
      cif,
      guid,
      tpn,
      investId,
      sessionId: cookies.get(SESSION_ID_COOKIE_NAME) || 'unknown',
      application: APP_BUILD_INFO.name,
    })

    setRegistrationLoadState(LoadState.LOADED)
    log.info({ message: '[USER] Registered with LR' })
  }, [userId, name, cif, guid, tpn, investId, loadState])

  return loadState
}
