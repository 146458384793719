/* eslint-disable react/destructuring-assignment */
import styled, { css } from 'styled-components'
import React, { ReactNode } from 'react'
import { get, Box, Link as MuiLink, TextHeading } from '@ally/metronome-ui'

interface InfoRowDefaultProps {
  title: string
  content: ReactNode
  className?: string
  type: 'Default'
}

interface InfoRowLinkProps {
  title: string
  content: string
  className?: string
  type: 'Link'
}

type InfoRowProps = InfoRowDefaultProps | InfoRowLinkProps

interface DebugPanelSectionProps {
  title?: string
}

export const Info = styled(Box)`
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const InfoHeading = styled(Box)`
  font-weight: bold;
  margin-right: 25px;
  font-size: 14px;
`

const TruncatedLink = styled(MuiLink)(
  () => css`
    a {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }
  `,
)

export const InfoRowBase: React.FC<InfoRowProps> = props => (
  <div className={props.className}>
    <InfoHeading>{props.title}</InfoHeading>
    {props.type === 'Default' ? (
      <Info>{props.content}</Info>
    ) : (
      <TruncatedLink text={props.content}>
        <a href={props.content} target="_blank" rel="noreferrer">
          content replaced by metronome
        </a>
      </TruncatedLink>
    )}
  </div>
)

export const InfoRow = styled(InfoRowBase)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  white-space: nowrap;
`

export const InfoColumn = styled(Box)`
  margin: 10px 0;
  overflow-x: auto;
  > div:nth-child(odd) {
    background-color: ${get.colors('light-gray')};
    border: 1px solid rgb(0, 0, 0, 0.03);
    border-left: none;
    border-right: none;
  }
`

export const StyledPanelSectionHeading = styled(TextHeading)`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 6px;
`

export const DebugPanelSection: React.FC<DebugPanelSectionProps> = ({
  title,
  children,
}) => (
  <Box mb="md">
    <StyledPanelSectionHeading size="xs" tag="h3">
      {title}
    </StyledPanelSectionHeading>
    <Box mt="sm">{children}</Box>
  </Box>
)

export const DebugPanelSubSection: React.FC<DebugPanelSectionProps> = ({
  title,
  children,
}) => (
  <Box mb="sm">
    <TextHeading size="xs" tag="h4">
      {title}
    </TextHeading>
    {children}
  </Box>
)
