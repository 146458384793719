import React from 'react'
import styled from 'styled-components'
import { Federator } from '@ally/federator'
import { Accordion, Box } from '@ally/metronome-ui'

import { contentFederator, headerFederator } from '../../../federation'
import { DebugPanelSubSection } from '../Shared'

const StyledRouteSchema = styled(Box)`
   {
    color: white;
    background-color: #2e2e2e;
    border: none;
    border-radius: 6px;
    line-height: 14px;
    font-family: Monaco, Menlo, monospace;
    font-size: 11px;
    margin-top: 10px;
    white-space: pre;
    overflow-y: auto;
    max-height: 200px;
  }
`

const RouteSchemaPanel: React.FC<{ federator: Federator }> = ({
  federator,
}) => {
  const routeSchema = JSON.stringify(federator.apps, null, 2)

  return (
    <StyledRouteSchema size="sm" tabIndex={-1}>
      {routeSchema}
    </StyledRouteSchema>
  )
}

export const RouteSchema: React.FC = () => {
  return (
    <DebugPanelSubSection>
      <Accordion
        disableContainer
        items={[
          {
            heading: 'Header Route Schema',
            panel: <RouteSchemaPanel federator={headerFederator} />,
          },
        ]}
      />
      <Accordion
        disableContainer
        items={[
          {
            heading: 'Content Route Schema',
            panel: <RouteSchemaPanel federator={contentFederator} />,
          },
        ]}
      />
    </DebugPanelSubSection>
  )
}

export default RouteSchema
