import { useState } from 'react'

export const useAsyncFocus = (): ((selector: string) => boolean) => {
  const [savedObserver, setSavedObserver] = useState<MutationObserver | null>(
    null,
  )
  return (selector: string): boolean => {
    if (savedObserver) {
      savedObserver.disconnect()
      setSavedObserver(null)
    }
    const targetEl = document.querySelector<HTMLElement>(selector)
    // If we find an element with a "data-anchor-id" we immediately focus it
    if (targetEl) {
      targetEl.focus()
      return true
    }
    // Observe the DOM for any mutations within the app
    const observer = new MutationObserver((): void => {
      const targetNode = document.querySelector<HTMLElement>(selector)
      // If element is loaded later we focus it
      if (targetNode) {
        targetNode.focus()
        // disconnect once element is found
        observer.disconnect()
        setSavedObserver(null)
      }
    })

    setSavedObserver(observer)

    observer.observe(document.getElementById('app') || document, {
      attributes: false,
      childList: true,
      subtree: true,
    })
    return false
  }
}
