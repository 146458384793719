import React, { useEffect } from 'react'
import styled from 'styled-components'
import cookies from 'js-cookie'
import { getJSONSessionStorage, setJSONSessionStorage } from '@ally/utilitarian'
import { Box, TextBody, Icon, Space, Button } from '@ally/metronome-ui'
import { deprecated } from '@ally/metronome-icons'
import { env, hideIsMRGatewayUrl } from '../../constants'

/**
 * This component's purpose is to alert devs working in host to know which MR Gateway cookies
 * have been set. The MR Gateway cookies (and URL params) allow AWS to know what MR release code
 * to overwrite in the MR Gateway to allow for testing. Devs can then remove cookies as needed to
 * refresh/reset the environment.
 **/

const StyledBox = styled(Box)(
  ({ theme: { colors, space } }): string => `
    padding: ${space.md} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.black};
    opacity: 0.6;
    background-color: ${colors.cc};
    overflow: hidden;
  `,
)

const ButtonBox = styled(Box)`
  gap: 10px;
`
// Resets location after cookies have been removed. This function will also remove any MR URL params
export const resetLocation = (paramNames: string[]): void => {
  const params = new URLSearchParams(window.location.search)
  paramNames.forEach(param => {
    params.delete(param)
  })
  const search = params.toString().length > 0 ? `?${params}` : ''
  const href = `${window.location.pathname}${search}`
  window.history.replaceState({}, document.title, href)
  window.location.reload()
}

// Clears all cookies, then calls resetLocation
export const clearAllCookies = (): void => {
  cookies.remove('hostMR')
  cookies.remove('emberMR')
  cookies.remove('assetsMR')
  resetLocation(['hostMR', 'emberMR', 'assetsMR'])
}

/**
 * Function to remove individual cookie
 * Useage example:
 * ```typescript
 * const example = removeCookie('hostMR')
 * ```
 **/
export const removeCookie = (cookieName: string): void => {
  cookies.remove(cookieName)
  resetLocation([cookieName])
}

// MR Gateway Notification React component
const MRGatewayNotification: React.FC = () => {
  const isHidden = !!getJSONSessionStorage(hideIsMRGatewayUrl)
  const [isDismissed, setIsDismissed] = React.useState<boolean>(isHidden)
  const [hostMRCookie, setHostMRCookie] = React.useState<string>('')
  const [emberMRCookie, setEmberMRCookie] = React.useState<string>('')
  const [assetsMRCookie, setAssetsMRCookie] = React.useState<string>('')
  const [isUsingMRGateway, setisUsingMRGateway] = React.useState<boolean>(false)

  const checkForMRCookies = (): void => {
    setHostMRCookie(cookies.get('hostMR') || '')
    setEmberMRCookie(cookies.get('emberMR') || '')
    setAssetsMRCookie(cookies.get('assetsMR') || '')
  }

  useEffect(() => {
    checkForMRCookies()
  }, [])

  useEffect(() => {
    setisUsingMRGateway(
      !!(
        hostMRCookie.length > 0 ||
        emberMRCookie.length > 0 ||
        assetsMRCookie.length > 0
      ),
    )
  }, [hostMRCookie, emberMRCookie, assetsMRCookie])

  const closeWarning = (): void => {
    setIsDismissed(true)
    setJSONSessionStorage(hideIsMRGatewayUrl, true)
  }

  const shouldDisplay =
    (env.isDevLike || env.isQA) && isUsingMRGateway && !isDismissed

  if (!shouldDisplay) return null

  return (
    <StyledBox>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Box display="grid" gridTemplateColumns="1fr 3fr 1fr" width="100%">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gridColumn="2"
          >
            <Space mr="xxs">
              <Icon
                icon={deprecated.muiWarningFill}
                size="md"
                fill="slate-5"
                ariaHidden
              />
            </Space>
            <TextBody tag="p" size="md" weight="bold">
              Warning
            </TextBody>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gridColumn="3"
          >
            <Button
              variant="link"
              icon={{
                map: deprecated.muiClose,
              }}
              onClick={closeWarning}
              data-testid="notification-close-btn"
            />
          </Box>
        </Box>
        <Box>
          <TextBody tag="p" size="sm" weight="bold">
            You are now using a MR release
          </TextBody>
          <TextBody tag="p" size="sm">
            This may override some features based on contents of merge request
          </TextBody>
          <TextBody tag="p" size="sm">
            To leave MR environment, use the following reset button(s) to reset
            url parameters and MR cookies
          </TextBody>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="xs"
          >
            <Space mr="xxs">
              <Icon
                icon={deprecated.muiInfoFill}
                size="sm"
                fill="black"
                ariaHidden
              />
            </Space>
          </Box>
        </Box>
        <ButtonBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
        >
          {hostMRCookie.length > 0 && (
            <Space mt="xs">
              <Button
                data-cookie-name="hostMR"
                variant="destructive"
                text={`Reset Host MR Cookie (MR #${hostMRCookie})`}
                data-testid="remove-hostMR-cookie"
                onClick={(): void => removeCookie('hostMR')}
              />
            </Space>
          )}
          {emberMRCookie.length > 0 && (
            <Space mt="xs">
              <Button
                data-cookie-name="emberMR"
                variant="destructive"
                text={`Reset Ember MR Cookie (MR #${emberMRCookie})`}
                data-testid="remove-emberMR-cookie"
                onClick={(): void => removeCookie('emberMR')}
              />
            </Space>
          )}
          {assetsMRCookie.length > 0 && (
            <Space mt="xs">
              <Button
                data-cookie-name="assetsMR"
                variant="destructive"
                text={`Reset Assets MR Cookie (MR #${assetsMRCookie})`}
                data-testid="remove-assetsMR-cookie"
                onClick={(): void => removeCookie('assetsMR')}
              />
            </Space>
          )}
          <Space mt="xs">
            <Button
              variant="destructive"
              text="Reset All MR Cookies"
              onClick={clearAllCookies}
            />
          </Space>
        </ButtonBox>
      </Box>
    </StyledBox>
  )
}

export default React.memo(MRGatewayNotification)
