import * as React from 'react'
import styled from 'styled-components'
import { get, SkeletonLoader } from '@ally/metronome-ui'
import { MaxAppWidth } from '@ally/next-layout'

const SkeletonWrapper = styled.div`
  *,
  ::before,
  ::after {
    margin: 0;
    box-sizing: border-box;
  }

  && {
    width: 100%;
    margin: ${get.space('lg')} auto;
  }
`

const RouteSkeleton: React.FC = () => (
  <MaxAppWidth>
    <SkeletonWrapper data-testid="skeleton-loader">
      <SkeletonLoader.Container data-testid="route-skeleton">
        <SkeletonLoader.Row height="xl" width="xl" />
        <SkeletonLoader.Row height="xl" width="xl" />
        <SkeletonLoader.Row height="xl" width="xl" />
      </SkeletonLoader.Container>
    </SkeletonWrapper>
  </MaxAppWidth>
)

export default RouteSkeleton
