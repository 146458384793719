/**
 * When first given a single or array of RegExp patterns, this function
 * will return a new function that takes in a "Location-like" object
 * (has a "pathname" property) and returns whether the "pathname"
 * matches any of the given patterns.
 */
export function isPathMatch(
  pattern: RegExp | RegExp[],
): (location: { pathname: string }) => boolean {
  const patterns = Array.isArray(pattern) ? pattern : [pattern]
  return ({ pathname }): boolean => {
    return patterns.some(p => p.test(pathname))
  }
}
