import React from 'react'
import { GlobalNav } from '@ally/federated-types'

const GlobalNavContext = React.createContext<GlobalNav>({} as GlobalNav)

export const useGlobalNav = (): GlobalNav => React.useContext(GlobalNavContext)

export const GlobalNavProvider: React.FC = ({ children }) => {
  const [isHidden, setIsHidden] = React.useState(false)

  return (
    <GlobalNavContext.Provider value={{ setIsHidden, isHidden }}>
      {children}
    </GlobalNavContext.Provider>
  )
}
