import { Subject } from 'rxjs'

export type TimerOptions = {
  time: number
  repeat?: boolean
}

export type TimerEvent = {
  name: 'START' | 'STOP' | 'TIMEOUT'
}

/**
 * Since node types are in here, TS thinks setTimeout is returning a
 * NodeJS.Timeout object. This fixes that.
 */
const timeout = (f: () => unknown, n: number): number => {
  return (setTimeout(f, n) as unknown) as number
}

class Timer {
  private time: number

  private repeat: boolean

  private timer: number | null = null

  private eventsSubject$ = new Subject<TimerEvent>()

  public events$ = this.eventsSubject$.asObservable()

  constructor(options: TimerOptions) {
    this.time = options.time
    this.repeat = options.repeat ?? false
  }

  private onTimeout(): void {
    this.eventsSubject$.next({ name: 'TIMEOUT' })
    this.timer = null
    if (this.repeat) this.start()
  }

  start(): void {
    if (this.timer) return
    this.eventsSubject$.next({ name: 'START' })
    this.timer = timeout(this.onTimeout.bind(this), this.time)
  }

  stop(): void {
    if (!this.timer) return
    clearTimeout(this.timer)
    this.timer = null
    this.eventsSubject$.next({ name: 'STOP' })
  }
}

export default Timer
