import { once } from '@ally/utilitarian'
import { BehaviorSubject, Observable } from 'rxjs'
import { HeaderState } from '@ally/federated-types'
import { toProxyObject } from '../toProxyObject'

export class HeaderService {
  private stateSubject: BehaviorSubject<HeaderState> = new BehaviorSubject<HeaderState>(
    {
      showFDICBanner: false,
    },
  )

  readonly state$: Observable<HeaderState> = this.stateSubject.asObservable()

  setShowFDICBanner(value: boolean): void {
    this.stateSubject.next({
      ...this.stateSubject.getValue(),
      showFDICBanner: value,
    })
  }
}

export default once(() => toProxyObject(new HeaderService()))
