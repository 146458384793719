import React from 'react'
import { get, Space, TextHeading } from '@ally/metronome-ui'
import { useFauxnamiCode } from '@ally/fauxnami'
import SlidingDrawer from '@ally/metronome-sliding-drawer'
import styled from 'styled-components'
import AppDetails from './AppDetails'
import AppSettings from './AppSettings'
import { tracks, TrackingEvent } from '../../tracking'

const StyledSlidingDrawer = styled(SlidingDrawer)`
  width: 100%;
  ${get.media.up('md')} {
    width: 500px;
  }
`

export const DebugPanelContent: React.FC = () => (
  <>
    <Space mb="8px">
      <TextHeading focusOnMount tag="h2" size="md">
        Debug Panel
      </TextHeading>
    </Space>
    <AppDetails />
    <AppSettings />
  </>
)

/**
 * Displays important app/build information useful for debugging purposes.
 * Uses the `debugPanel` keycode to enable the drawer (see
 * constants.ts).
 */
const DebugPanel: React.FC = () => {
  const code = useFauxnamiCode('debugPanel')

  return (
    <StyledSlidingDrawer
      show={code.active}
      onOpened={tracks(TrackingEvent.DebugPanelOpened)}
      onClosing={code.disable}
      isAriaModal
    >
      <DebugPanelContent />
    </StyledSlidingDrawer>
  )
}

export default DebugPanel
