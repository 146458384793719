import React, { FC, useCallback, useState } from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  CheckboxGroup,
  get,
  Space,
  TextBody,
  TextHeading,
} from '@ally/metronome-ui'

import Warpgate from './Warpgate'
import LogLevel from './LogLevel'
import LogScrubbing from './LogScrubbing'
import SessionTimeout from './SessionTimeout'
import ProdSwitcher from './ProdSwitcher'
import { ApplicationSettingsForm } from './ApplicationSettingsForm'
import { InfoColumn, InfoRow } from '../Shared'
import { env, idleSessionExpiresSec } from '../../../constants'

const SettingsContainer = styled(Box)`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: ${get.space('lg')};
`

const ApplicationSettingsHeadingContainer = styled.section`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const FullWidthButton = styled(Button)`
  width: 100%;
`

export enum UpdateFormResult {
  Success,
  Abort,
}

export type UpdateForm = <Func extends FormResultFunc>(
  func: Func,
) => (...args: Parameters<Func>) => void

type FormResultFunc = (...args: any[]) => UpdateFormResult

interface EnvironmentOnlyProps {
  isEnv: boolean
}

const EnvironmentOnly: React.FC<EnvironmentOnlyProps> = ({
  isEnv,
  children,
}) => {
  if (isEnv) {
    return <>{children}</>
  }

  return null
}

const AppSettings: FC = () => {
  const [isDirty, setIsDirty] = useState(false)

  const updateForm: UpdateForm = useCallback(
    <Func extends FormResultFunc>(func: Func) => {
      return (...args: Parameters<Func>): void => {
        const result = func(...args)

        // The reason we need abort logic is due to RadioGroup default behavior
        // of running onChange on mount
        if (result === UpdateFormResult.Abort) {
          return
        }

        setIsDirty(true)
      }
    },
    [setIsDirty],
  )

  return (
    <>
      <ApplicationSettingsHeadingContainer>
        <TextHeading size="sm" tag="h3">
          Application Settings
        </TextHeading>
        {isDirty && (
          <TextBody tag="span" size="md">
            (Unsaved)
          </TextBody>
        )}
      </ApplicationSettingsHeadingContainer>

      <Space mt="sm" mb="md">
        <TextBody tag="p" size="sm">
          The following options are adjustments you can make to your local
          environment. After saving, the browser will refresh to apply any
          changes.
        </TextBody>
      </Space>

      <Box mb="sm">
        <InfoColumn>
          <EnvironmentOnly isEnv={!env.isLocal}>
            <InfoRow
              title="Session Timeout"
              content={`${idleSessionExpiresSec} (sec)`}
              type="Default"
            />
          </EnvironmentOnly>
          <EnvironmentOnly isEnv={!env.isProd}>
            <InfoRow
              title="Prod Switcher"
              content="Only available in production"
              type="Default"
            />
          </EnvironmentOnly>
          <EnvironmentOnly isEnv={!env.isDevLike}>
            <InfoRow title="Warpgate" content="Enabled" type="Default" />
          </EnvironmentOnly>
        </InfoColumn>
      </Box>

      <ApplicationSettingsForm updateForm={updateForm}>
        <EnvironmentOnly isEnv={env.isLocal}>
          <SettingsContainer>
            <SessionTimeout />
          </SettingsContainer>
        </EnvironmentOnly>

        <SettingsContainer>
          <LogLevel />
        </SettingsContainer>

        <EnvironmentOnly isEnv={env.isProd}>
          <SettingsContainer>
            <ProdSwitcher />
          </SettingsContainer>
        </EnvironmentOnly>

        <Box pb="sm">
          <CheckboxGroup name="otherOptions" legendText="Other options">
            <EnvironmentOnly isEnv={env.isDevLike}>
              <Warpgate />
            </EnvironmentOnly>
            <LogScrubbing />
          </CheckboxGroup>
        </Box>

        <FullWidthButton type="submit" text="Save" />
      </ApplicationSettingsForm>
    </>
  )
}

export default AppSettings
