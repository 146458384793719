import { useEffect, useRef } from 'react'
import { SessionContextValue } from '../../providers'

class DeferredPromise<D> {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  public promise: Promise<D>

  public resolve!: (d: D) => void

  public reject!: (e: Error) => void
}

/**
 * Given a session object this function will return a pending promise
 * when the user is not fully authenticated (session and legacy chat token).
 */
export const useAwaitSession = (
  session: SessionContextValue,
): Promise<SessionContextValue> => {
  const { current: deferred } = useRef(
    new DeferredPromise<SessionContextValue>(),
  )

  useEffect(() => {
    if (session.status === 'Authenticated' && !!session.data?.legacyChatToken) {
      deferred.resolve(session)
    }
  }, [session])

  if (session.status === 'Authenticated' && !!session.data?.legacyChatToken)
    return Promise.resolve(session)
  return deferred.promise
}
