import { useEffect, useState } from 'react'

export interface UseTimerHook {
  clearTimer: () => void
  resetTimer: () => void
  startTimer: () => void
}

/**
 * Given a function to call and delay (ms), this hook will call
 * the function after the delay has elapsed. This hook provides
 * the following functions:
 *
 * `startTimer`: Starts the timer (it does not start on mount)
 *
 * `resetTimer`: Resets time left on timer (still active)
 *
 * `clearTimer`: Resets and cancels timer (now inactive)
 */
export function useTimer(fn: () => any, delay: number): UseTimerHook {
  const [timer, setTimer] = useState<number | null>(null)
  const [timerCalled, setTimerCalled] = useState(false)

  useEffect(() => {
    if (timerCalled) {
      setTimerCalled(false)
      fn()
    }
  }, [timerCalled, fn])

  const fireTimer = (): void => setTimerCalled(true)

  const resetTimer = (): void => {
    if (timer !== null) window.clearTimeout(timer)
    const newTimer = window.setTimeout(fireTimer, delay)
    setTimer(newTimer)
  }

  const clearTimer = (): void => {
    if (timer !== null) window.clearTimeout(timer)
    setTimer(null)
  }

  // Clear timer on unmount
  useEffect(() => clearTimer, [])

  return {
    clearTimer,
    resetTimer,
    startTimer: resetTimer,
  }
}
