import { noop, throttle, flow, constant } from '@ally/utilitarian'
import React, { useMemo, useState, useEffect, useCallback } from 'react'

import log from '../../whisper'
import { TimeoutManager } from './TimeoutManager'
import { SessionTimeoutModal } from './SessionTimeoutModal'
import { useSession, isAuthenticated } from '../../providers'

import {
  idleSessionExpiresSec,
  idleSessionWarningSec,
  idleSessionResetEventNames,
} from '../../constants'
import { getLogout } from '../../utils/logout'
import { useServices } from '../../services/ServicesProvider'

const timeouts = TimeoutManager<'WARNING' | 'EXPIRED'>({
  events: [
    {
      delay: idleSessionWarningSec * 1000,
      status: 'WARNING',
    },
    {
      delay: idleSessionExpiresSec * 1000,
      status: 'EXPIRED',
    },
  ],
})

/**
 * Listens to click/keydown/mousemove events on the document.
 * If a session timeout is running, it will be restarted by any of these events.
 * Returns a function that can be returned to a `useEffect` callback that will
 * uninstall any subscribed listeners.
 */
function subscribeActivityListeners(reset: VoidFunction): () => void {
  const restart = throttle(1000, () => {
    log.debug({ message: 'User activity reset.' })
    reset()
  })

  log.debug({ message: 'Activity event listeners subscribed.' })
  idleSessionResetEventNames.forEach((e: string) =>
    document.addEventListener(e, restart),
  )

  return (): void => {
    log.debug({ message: 'Activity event listeners unsubscribed.' })
    idleSessionResetEventNames.forEach((e: string) =>
      document.removeEventListener(e, restart),
    )
  }
}

/**
 * Keeps track of the user's session activity/inactivity.
 * When the user logs in (has an active session), timeouts are set that will
 * keep track of whether or not the user has been idle for too long. These
 * timeouts are reset by "activity event listeners" registered on the document.
 */
export const SessionTimeout: React.FC = () => {
  const session = useSession()
  const { autoLogin } = useServices()
  const [status, setStatus] = useState('IDLE')
  const [unsubscribe, setUnsubscribe] = useState(noop)
  const [isModalShown, setModalShown] = useState(false)

  const logout = useMemo(() => getLogout(session, autoLogin), [
    session,
    autoLogin,
  ])
  const { start, clear, reset } = useMemo(() => timeouts(setStatus), [
    setStatus,
  ])

  const hideModal = useCallback(() => setModalShown(false), [])
  const subscribe = useCallback(
    () => setUnsubscribe(() => subscribeActivityListeners(reset)),
    [reset],
  )

  useEffect(() => unsubscribe, [unsubscribe])
  useEffect(() => {
    const hasSession = isAuthenticated(session)
    const handleSetup = flow(subscribe, start, hideModal)
    const handleClear = flow(unsubscribe, clear, hideModal)
    const handleExpired = flow(handleClear, constant('inactive'), logout)
    const handleWarning = flow(unsubscribe, () => setModalShown(true))

    switch (true) {
      case status !== 'IDLE' && !hasSession:
        handleClear()
        break
      case status === 'IDLE' && hasSession:
        handleSetup()
        break
      case status === 'EXPIRED':
        handleExpired()
        break
      case status === 'WARNING':
        handleWarning()
        break
      default:
    }
  }, [clear, start, status, session, hideModal, subscribe, logout, unsubscribe])

  return (
    <SessionTimeoutModal
      restart={flow(hideModal, subscribe, start)}
      session={session}
      autoLogin={autoLogin}
      open={isModalShown}
      setOpen={setModalShown}
    />
  )
}

export default SessionTimeout
