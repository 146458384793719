import { useState, useEffect } from 'react'
import warpgate, { WG } from '@ally/warpgate'

import { getLDClientBankMemo } from '../../providers/bootstrap/tasks/features'
import { DEFAULT_EMBER_ROUTES, DEFAULT_REACT_ROUTES } from './constants'
import { app, isWarpgateEnabled } from './utils'

export const useWarpgate = (): WG | null => {
  const [wg, setWarpgate] = useState<WG | null>(null)
  const isActive = isWarpgateEnabled()
  const { client, getInitPromise } = getLDClientBankMemo()

  useEffect(() => {
    getInitPromise().then(() => {
      const defaultTarget = client.variation('warpgate-default-target', 'next') // 'next' or 'ember'
      setWarpgate(
        warpgate({
          app,
          isActive,
          defaultTarget,
          getRoutes: () => {
            const routesFlagName =
              defaultTarget === 'next' ? 'ember-routes' : 'react-routes'
            const routesFallback =
              defaultTarget === 'next'
                ? DEFAULT_EMBER_ROUTES
                : DEFAULT_REACT_ROUTES
            const routes = client.variation(routesFlagName, routesFallback)
            return routes
          },
        }),
      )
    })
  }, [])

  return wg
}
