import React, { FC, FormEvent } from 'react'
import styled from 'styled-components'
import { get } from '@ally/metronome-ui'
import {
  ApplicationSettingsContext,
  ApplicationSettingsContextValues,
} from './ApplicationSettingsContext'

const ApplicationSettingsContainer = styled.form`
  display: grid;
  grid-gap: ${get.space('lg')};
`

type ApplicationSettingsFormProps = ApplicationSettingsContextValues

export const ApplicationSettingsForm: FC<ApplicationSettingsFormProps> = ({
  updateForm,
  children,
}) => {
  const onFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    window.location.reload()
  }

  return (
    <ApplicationSettingsContext.Provider value={{ updateForm }}>
      <ApplicationSettingsContainer onSubmit={onFormSubmit}>
        {children}
      </ApplicationSettingsContainer>
    </ApplicationSettingsContext.Provider>
  )
}
