import {
  TrackEventParams,
  TrackPageViewParams,
  TrackImpressionParams,
} from '@ally/federated-types'

import log from '../../../whisper'
import getLogRocket from '../../../services/logRocket/service'

export const trackEvent = (params: TrackEventParams): void => {
  const { eventType, eventName, eventTag } = params

  window.allytm.event(eventType, {
    tagName: eventTag,
    name: eventName,
  })
}

export const trackPageView = ({
  eventType,
  pageName,
  options,
}: TrackPageViewParams): void => {
  try {
    window.allytm.event(eventType, pageName, options)
  } catch (e) {
    getLogRocket().captureException(e as Error)

    log.warn({
      message: [
        'Analytics failed to register pageview event:',
        (e as Error).stack,
      ],
    })
  }
}

export const trackImpression = ({
  eventType,
  impressions,
}: TrackImpressionParams): void => {
  try {
    window.allytm.event(eventType, impressions)
  } catch (e) {
    getLogRocket().captureException(e as Error)

    log.warn({
      message: [
        'Analytics failed to register impression event:',
        (e as Error).stack,
      ],
    })
  }
}
