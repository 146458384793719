import { Stats } from '@ally/federator'
import React, { ReactElement } from 'react'
import { getJSONLocalStorage } from '@ally/utilitarian'

import { Accordion } from '@ally/metronome-ui'

import {
  registry,
  contentFederator,
  getHostAppDetails,
  headerFederator,
} from '../../../federation'

import { devRoutesStorageKey } from '../../../constants'
import { InfoRow, InfoColumn, DebugPanelSubSection } from '../Shared'

type Routes = Record<string, unknown>

interface AppInfoAccordionProps {
  title: string
  status?: string
}

interface ApplicationInfoProps {
  className?: string
}

const AppInfoAccordion: React.FC<AppInfoAccordionProps> = ({
  title,
  children,
}) => (
  <Accordion
    disableContainer
    items={[
      {
        heading: title,
        panel: <>{children}</>,
      },
    ]}
  />
)

export const toRemoteAppInfo = (
  stats: Stats['meta'],
  devRoutes = getJSONLocalStorage<Routes>(devRoutesStorageKey) || {},
) => ({
  name,
  branch,
  commit,
  source,
  release,
  timestamp,
}: AppDetails): ReactElement => {
  const { mounts = '?', unmounts = '?', isMounted } = stats[name] || {}
  const isDevRoute = !!devRoutes[name]

  let status
  switch (true) {
    case isMounted:
      status = 'mounted'
      break
    case isDevRoute:
      status = 'dev'
      break
    default:
      status = 'default'
  }

  return (
    <AppInfoAccordion key={commit} title={name} status={status}>
      <InfoColumn tabIndex={-1}>
        <InfoRow title="App Type" content="remote" type="Default" />
        <InfoRow title="Source" content={source} type="Link" />
        <InfoRow title="Release" content={release} type="Default" />
        <InfoRow
          title="Timestamp"
          content={`${new Date(timestamp).toLocaleString()}`}
          type="Default"
        />
        <InfoRow title="Commit" content={commit} type="Default" />
        <InfoRow title="Branch" content={branch} type="Default" />
        <InfoRow
          title="Mounts/Unmounts"
          content={`${mounts}/${unmounts}`}
          type="Default"
        />
      </InfoColumn>
    </AppInfoAccordion>
  )
}

export const HostAppInfo: React.FC = () => {
  const { name, branch, commit, release, timestamp } = getHostAppDetails()

  return (
    <AppInfoAccordion title={name} status="mounted">
      <InfoColumn key={commit} tabIndex={-1}>
        <InfoRow title="App Type" content="host" type="Default" />
        <InfoRow title="Source" content={window.location.origin} type="Link" />
        <InfoRow title="Release" content={release} type="Default" />
        <InfoRow
          title="Timestamp"
          content={`${new Date(timestamp).toLocaleString()}`}
          type="Default"
        />
        <InfoRow title="Commit" content={commit} type="Default" />
        <InfoRow title="Branch" content={branch} type="Default" />
      </InfoColumn>
    </AppInfoAccordion>
  )
}

const Applications: React.FC<ApplicationInfoProps> = () => {
  const combinedStats = {
    ...contentFederator.stats.meta,
    ...headerFederator.stats.meta,
  }
  return (
    <DebugPanelSubSection title="Applications">
      <>
        <HostAppInfo />
        {registry.map(toRemoteAppInfo(combinedStats))}
      </>
    </DebugPanelSubSection>
  )
}

export default Applications
