// -------------------------------------------------------------------------- //
// WARNING: THIS CONTENT IS DUPLICATED IN `src/components/Footer/Disclosures`
// IF YOU CHANGE IT HERE, YOU PROBABLY SHOULD CHANGE IT THERE...
// -------------------------------------------------------------------------- //

import { deprecated } from '@ally/metronome-icons'
import { Box, get, Icon, Space, TextBody } from '@ally/metronome-ui'
import React from 'react'
import styled, { css } from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { FeatureFlag } from '../../hooks'
import { useBootstrap } from '../../providers'

const disclosuresStyles = css`
  color: ${get.colors('slate-4')};
  ${space}
`
const DisclosuresTextSection = styled(TextBody).attrs({
  tag: 'p',
  size: 'sm',
  mt: 'xxs',
})<SpaceProps>`
  ${disclosuresStyles}
`

const DisclosuresText = styled(TextBody).attrs({
  tag: 'span',
  size: 'sm',
})`
  ${disclosuresStyles}
`

const PrintBox = styled(Box)`
  display: none;

  @media print {
    display: block;
    overflow: visible;
  }
`

export const PrintFooter: React.FC = () => {
  const { services } = useBootstrap()

  const featureFlags = {
    isCreditCardEnabled: services?.featureFlags.bank.client.variation(
      FeatureFlag.CreditCardEnabled,
      false,
    ),
  }

  return (
    <PrintBox>
      <DisclosuresTextSection>
        A FEW THINGS YOU SHOULD KNOW
      </DisclosuresTextSection>
      <Space mt="md">
        <DisclosuresTextSection>
          Securities products and services offered through Ally Invest
          Securities LLC, member <strong>FINRA/SIPC</strong>. For background on
          Ally Invest Securities go to <strong>FINRA’s BrokerCheck</strong>.
          Advisory services offered through Ally Invest Advisors Inc., a
          registered investment adviser. Ally Bank, Ally Invest Advisors, and
          Ally Invest Securities are wholly owned subsidiaries of Ally Financial
          Inc. <strong>View disclosures</strong>. Securities products are{' '}
          <strong>NOT FDIC INSURED</strong>,{' '}
          <strong>NOT BANK GUARANTEED</strong>, and{' '}
          <strong>MAY LOSE VALUE</strong>.
        </DisclosuresTextSection>
        <DisclosuresTextSection>
          Options involve risk and are not suitable for all investors. Review
          the{' '}
          <strong>
            Characteristics and Risks of Standardized Options brochure
          </strong>{' '}
          (PDF) before you begin trading options. Options investors may lose the
          entire amount of their investment or more in a relatively short period
          of time.
        </DisclosuresTextSection>
        <DisclosuresTextSection>
          Trading on margin involves risk. You can lose more funds than you
          deposit in a margin account. Please review{' '}
          <strong>Margin Account Agreement and Disclosure</strong> (PDF) for
          more information regarding margin trading.
        </DisclosuresTextSection>
      </Space>

      <Space my="md">
        <DisclosuresTextSection>
          Deposit and mortgage products are provided by Ally Bank, a Member FDIC
          and Equal Housing Lender
          <Icon
            icon={deprecated.muiEqualHousing}
            size="sm"
            fill="slate-4"
            ariaHidden
          />
          , NMLS ID 181005.
        </DisclosuresTextSection>
      </Space>
      <DisclosuresTextSection weight="bold">
        Ally Bank Disclosures
      </DisclosuresTextSection>
      <Space ml="md">
        <DisclosuresTextSection>
          Ally Financial Inc. (NYSE: ALLY) is a leading digital financial
          services company. Ally Bank, the company&apos;s direct banking
          subsidiary, offers an array of deposit and mortgage products and
          services. Ally Bank is a Member FDIC and{' '}
          <DisclosuresText weight="bold">Equal Housing Lender</DisclosuresText>{' '}
          <Icon
            icon={deprecated.muiEqualHousing}
            size="sm"
            fill="slate-4"
            ariaHidden
          />
          NMLS ID 181005. Mortgage credit and collateral are subject to approval
          and additional terms and conditions apply. Programs, rates and terms
          conditions are subject to change at any time without notice.
        </DisclosuresTextSection>
        {featureFlags.isCreditCardEnabled && (
          <DisclosuresTextSection>
            Ally Mastercard<sup>&reg;</sup> is issued by Ally Bank, Member FDIC
            under license from Mastercard<sup>&reg;</sup> International. FICO
            <sup>&reg;</sup> is a registered trademark of the Fair Isaac
            Corporation in the United States and other countries.
          </DisclosuresTextSection>
        )}
        <DisclosuresTextSection>
          App Store is a service mark of Apple Inc. Google Play is a trademark
          of Google Inc. Amazon Appstore is a trademark of Amazon.com Inc., or
          its affiliates. Windows Store is a trademark of the Microsoft group of
          companies.
        </DisclosuresTextSection>
        <DisclosuresTextSection>
          Zelle<sup>&reg;</sup> and the Zelle<sup>&reg;</sup> related marks are
          wholly owned by Early Warning Services, LLC and are used herein under
          license.
        </DisclosuresTextSection>
        <DisclosuresTextSection>
          From MONEY. © 2020 Ad Practitioners, LLC. All rights reserved. Used
          under license.
        </DisclosuresTextSection>
      </Space>
      <Space mt="md">
        <DisclosuresTextSection>
          Ally Auto accounts serviced by Ally Servicing LLC,{' '}
          <strong>NMLS ID 212403</strong>.
        </DisclosuresTextSection>
      </Space>
      <Space mt="md">
        <DisclosuresTextSection>
          ©2009 – {new Date().getFullYear()} Ally Financial, Inc.
        </DisclosuresTextSection>
      </Space>
    </PrintBox>
  )
}
